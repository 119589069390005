import { createContext, useContext } from "react";

type ToastType = "success" | "alert" | "error" | "info";
interface ToastContextValue {
  open: (
    title: string,
    message: string,
    type: ToastType,
    timeout?: number
  ) => void;
  close: (id: any) => void;
}

const ToastContext = createContext<ToastContextValue | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context as ToastContextValue;
};

export default ToastContext;

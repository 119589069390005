import React from "react";
import { Banner } from "models/banner";
import { BannerListResponse, getBanners } from "api/getBanners";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "components/common/toast/toastService";
import ShimmerEffect from "components/common/loader/ShimmerEffect";

export const Banners = () => {
  const {
    data: bannersData,
    isLoading,
    error,
  } = useQuery<BannerListResponse>({
    queryKey: ["banners"],
    queryFn: getBanners,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const toast = useToast();

  if (isLoading) {
    return (
      <div className="flex gap-4 mb-4 mt-2">
        <ShimmerEffect width={"50%"} height={"96px"} borderRadius={"20px"} />
        <ShimmerEffect width={"50%"} height={"96px"} borderRadius={"20px"} />
      </div>
    );
  }

  if (error) {
    toast.open("Error", "Failed to fetch Banners", "error", 5000);
  }

  return (
    <div className="flex flex-row px-2 py-1 gap-2 overflow-x-auto min-h-[104px] hide-scrollbar">
      {bannersData?.items.map((banner: Banner) => (
        <img
          key={banner.id}
          src={banner.imageUrl}
          alt={banner.title}
          className="cursor-pointer rounded-large w-full h-auto max-w-[275px] max-h-[104px]"
          style={{ objectFit: "cover" }}
        />
      ))}
    </div>
  );
};

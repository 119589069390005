import { Order } from "models/order";
import apiService from "./apiServices";
import { API_URL } from "utils/constants";

export const getOrderById = async (id: string): Promise<Order> => {
  const response = await apiService.get<Order>(
    `${API_URL}/app/order-lookup/${id}`
  );

  return response.data;
};

export const ORDER_STATUS_OPTIONS = {
  Delivered: "Delivered",
  PartiallyFailed: "PartiallyFailed",
  Failed: "Failed",
  Created: "Created",
  Processing: "Processing",
};

export interface Denomination {
  productName: string;
  amount: number;
  price: number;
}

export interface Order {
  id: string;
  orderId: string;
  supplierOrderId: string;
  orderStatus: number;
  deliveryMethod: string;
  totalItemQuantity: number;
  totalItemPrice: number;
  brandId: string;
  brandName: string;
  brandLogoUrl: string;
  lastModificationTime: string;
  lastModifierId: string;
  creationTime: string;
  creatorId: string;
  orderItems: OrderItem[];
  currencyCode: string;
  brandCountryCode: string;
  expanded?: boolean;
  clientEmail: string;
  isInWishlist?: boolean;
}

export interface OrderItem {
  orderItemId: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  unitFaceValue: number;
  productId: string;
  productName: string;
  productCurrencyCode: string;
  orderItemCards: OrderCode[];
}

export interface OrderCode {
  id: string;
  isRedeemed: boolean;
  cardStatus: string;
}

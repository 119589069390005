import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Option } from "models/options";
import { TextField } from "components/common/textField";

interface InputProps {
  data: Option[];
  selectedOption: Option | null;
  onSelect: (option: Option) => void;
  placeholder?: string;
  inputPlaceholder?: string;
}

export const SearchDropdown = ({
  data,
  selectedOption,
  onSelect,
  placeholder,
  inputPlaceholder,
}: InputProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const options = data.filter((x) => 
    searchTerm === "" || x.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  return (
    <Popover>
      <>
        <Popover.Button
          className="w-16 h-8 text-white focus:outline-none 
                focus-visible:ring-2 focus-visible:ring-white min-w-[60px]"
        >
          <button
            className={`w-full h-8 p-0 rounded-large cursor-pointer text-sm font-normal text-input-label shadow-none hover:shadow-none ${
              selectedOption
                ? "border border-input-border-selected"
                : "border border-input-border"
            }`}
          >
            <div className="py-1 grid grid-cols-2 px-2">
              <span className="text-sm mt-0.5">
                {selectedOption ? selectedOption?.name : placeholder || ""}
              </span>
              <ChevronDownIcon className="h-6 text-input-icon ml-0.5" />
            </div>
          </button>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute right-0 z-30 max-w-40 transform px-4 sm:px-0 mt-2">
            {({ close }) => (
              <div className="overflow-hidden rounded-large shadow-lg">
                <div className="relative bg-white p-3">
                  <TextField
                    searchIcon={false}
                    placeholder={inputPlaceholder || "Search"}
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    className="border-2 border-selected shadow-none hover:shadow-none"
                  />
                  <div className="pt-2 max-h-64 overflow-y-auto">
                    {options && options.length > 0 ? (
                      options.map((option, index) => (
                        <div
                          key={index}
                          className={`relative hover:bg-selected cursor-pointer rounded-large py-1 my-1 whitespace-nowrap text-left pl-2 ${
                            selectedOption?.value === option.value &&
                            "bg-selected"
                          }`}
                          onClick={() => {
                            onSelect(option);
                            close && close();
                            setSearchTerm("");
                          }}
                        >
                          {" "}
                          {option.name}
                          {selectedOption?.value === option.value && (
                            <span className="absolute -mt-5 bottom-[0.35rem] right-6 flex items-center pl-3 text-btn-from">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </div>
                      ))
                    ) : (
                      <span className="text-input-label">Nothing found.</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
};

import { useEffect, useState, useCallback } from "react";
import { Cart } from "models/cartItem";
import { getCartItems } from "api/getCartItems";
import { useAuth } from "context/authContext";

export const loadCartFromLocalStorage = (): Partial<Cart> => {
  const cartData = localStorage.getItem("cart");
  return cartData
    ? JSON.parse(cartData)
    : { basketItems: [], totalItemQuantity: 0, totalPrice: 0 };
};

export const useCart = () => {
  const [cart, setCart] = useState<Cart | undefined>(undefined);
  const { isLoggedIn } = useAuth();

  const saveCartToLocalStorage = (cart: Cart) => {
    localStorage.setItem("cart", JSON.stringify(cart));
  };

  const clearCartInLocalStorage = () => {
    localStorage.removeItem("cart");
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchCart();
    } else {
      // Fetch local cart data if not logged in
      const storedCart = localStorage.getItem("cart");
      if (storedCart) {
        setCart(JSON.parse(storedCart));
      }
    }
  }, [isLoggedIn]);

  const fetchCart = useCallback(async () => {
    const localCart: Partial<Cart> = loadCartFromLocalStorage();

    if (isLoggedIn) {
      try {
        const serverCart = await getCartItems();

        if (serverCart && serverCart.basketItems) {
          const mergedCartItems = [...(localCart.basketItems || [])];

          serverCart.basketItems.forEach((serverItem) => {
            const existingItem = mergedCartItems.find(
              (item) =>
                item.productId === serverItem.productId &&
                item.unitFaceValue === serverItem.unitFaceValue
            );
            if (existingItem) {
              existingItem.quantity = serverItem.quantity;
              existingItem.totalPrice = serverItem.totalPrice;
            } else {
              mergedCartItems.push(serverItem);
            }
          });

          const updatedCart: Cart = {
            basketItems: mergedCartItems,
            totalItemQuantity: mergedCartItems.reduce(
              (sum, item) => sum + item.quantity,
              0
            ),
            totalPrice: mergedCartItems.reduce(
              (sum, item) => sum + (item.totalPrice || 0),
              0
            ),
            id: serverCart.id,
          };

          setCart(updatedCart);
          saveCartToLocalStorage(updatedCart); // Update local storage with the merged cart
          // clearCartInLocalStorage(); // Clear local storage after syncing with the server
        } else {
          setCart(localCart as Cart);
        }
      } catch (error) {
        console.error("Error fetching cart items:", error);
        setCart(localCart as Cart);
      }
    } else {
      setCart(localCart as Cart);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchCart();
    }
  }, [isLoggedIn]);

  return { cart, setCart, fetchCart };
};

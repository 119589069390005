import apiService from "./apiServices";
import { API_URL } from "utils/constants";

interface Currency {
  name: string;
  id: string;
  code: string;
}

export const getCurrencies = async (): Promise<Currency[]> => {
    const response = await apiService.get<Currency[]>(
      `${API_URL}/app/currency`
    );

    return response.data;
};

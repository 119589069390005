import { OrderPayload } from "models/orderPayload";
import apiService from "./apiServices";
import { API_URL } from "utils/constants";

export const createOrder = async (order: OrderPayload): Promise<any> => {
  return apiService
    .post(`${API_URL}/app/checkout`, order)
    .then((res: any) => {
      return res;
    });
};

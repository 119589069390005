import React, { ReactElement } from "react";

interface NavButtonProps {
  text: string;
  icon?: ReactElement;
  selected?: boolean;
  onClick: () => void;
}

export const NavButton = ({
  text,
  icon,
  selected,
  onClick,
}: NavButtonProps) => {
  return (
    <div
      className={`flex flex-col p-3 px-5 justify-center rounded-large transform transition-transform hover:scale-110 cursor-pointer h-12 
      max-w-[25%] max-h-12 ${selected && "bg-selected"}`}
      onClick={onClick}
    >
      {icon}
      <span
        className={`text-sm font-semibold ${selected && "text-primary-blue"}`}
      >
        {text}
      </span>
    </div>
  );
};

import GiftActiveIcon from "assets/gift_active.svg";
import GiftDisabledIcon from "assets/gift_disabled.svg";
import { OrderCode } from "models/order";

interface Props {
  title: string;
  codes: OrderCode[];
  onSelectCode: (code: OrderCode, product: string) => void;
}

export const ShowCode = ({ title, codes, onSelectCode }: Props) => {
  const onSelect = (code: OrderCode, title: string) => onSelectCode(code, title);

  return (
    <div className="pb-3">
      <div className="text-left px-3 text-base font-normal text-primary pb-2">
        {title}
      </div>
      {codes &&
        codes.map((code, index) => {
          return (
            <div
              key={`code-${index}`}
              className="grid grid-cols-12 gap-2 py-1 pr-1"
            >
              <div className="col-span-1 flex items-center justify-center text-lg font-normal text-primary">
                #{index + 1}
              </div>
              <div
                className="col-span-10 ml-2 bg-white border-2 border-selected rounded-large"
                onClick={() => onSelect(code, title)}
              >
                <div className="py-3 px-3 cursor-pointer text-sm font-normal text-primary">
                  Show Code
                </div>
              </div>
              <div className="col-span-1 flex items-center justify-center">
                <img
                  alt="logo"
                  src={!code.isRedeemed ? GiftActiveIcon : GiftDisabledIcon}
                  className="h-5 w-5 cursor-pointer"
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

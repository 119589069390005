export const routes = {
  index: "/",
  products: "/products",
  orders: "/orders",
  productDetail: "/products/:id",
  profile: "/profile",
  wishlist: "/profile/wishlist",
  settings: "/profile/settings",
  help: "/profile/help",
  privacyPolicy: "/profile/privacy-policy",
  orderDetail: "/orders/:id",
  checkout: "/checkout",
  binanceAuth: "/oauth/binance/callback",
  verifyPayment: "/verify-payment",
  orderStatus: "/order-status",
};

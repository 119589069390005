import apiService from "./apiServices";
import { API_URL } from "utils/constants";

// Define the expected response type
interface CategoryResponse {
  totalCount: number;
  items: { name: string; id: string }[];
}

export const getCategories = async (): Promise<CategoryResponse> => {
    const response = await apiService.get<CategoryResponse>(
      `${API_URL}/app/category?MaxResultCount=100&sorting=name`,
    );
    
    return response.data;
};

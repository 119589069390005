import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import TamanaLogo from "assets/tamana_logo.svg";
import { CartPopup } from "components/layout/cartPopup";
import Button from "components/common/button/button";
import { useToast } from "components/common/toast/toastService";
interface Props {
  headerTitle?: string;
  hideNavigation?: boolean;
  showTopup?: boolean;
  hideMiniCart?: boolean;
  onNavigateBack?: () => void;
  hasSearchedBrands?: boolean;
  setSearchedBrands?: (brands: any) => void;
}

export const Header = ({
  headerTitle,
  hideNavigation,
  onNavigateBack,
  showTopup = false,
  hideMiniCart = false,
  hasSearchedBrands,
  setSearchedBrands,
}: Props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();

  const goHome = () => navigate("/");
  const goBack = () => {
    !hideNavigation && onNavigateBack && onNavigateBack();
  };

  const showNotification = () =>
    toast.open(
      "You got a new message",
      "Wonderful special offer. Love discounts? Get a 10% off for your first order in the app",
      "info"
    );

  const isCheckoutPage = location.pathname.includes("checkout");
  const handleOnClick = () => {
    if (hasSearchedBrands) {
      setSearchedBrands && setSearchedBrands([]);
    } else {
      goBack();
    }
  };

  return (
    <div className="py-2 justify-between flex items-center">
      {headerTitle || hasSearchedBrands ? (
        <div
          className="w-1/2 justify-start gap-2 flex flex-row"
          onClick={handleOnClick}
        >
          {!hideNavigation && (
            <ChevronLeftIcon className="text-primary-blue h-6 w-6 pt-1 cursor-pointer" />
          )}
          <span className="text-lg">{headerTitle}</span>
        </div>
      ) : (
        <img
          src={TamanaLogo}
          alt="Tamana"
          height={21}
          width={104}
          onClick={goHome}
          className="cursor-pointer"
        />
      )}

      {showTopup && (
        <Button
          className="w-1/2 ml-4"
          text="Mobile Top-Up"
          onClick={showNotification}
        />
      )}
      {!hideMiniCart && !isCheckoutPage && <CartPopup />}
    </div>
  );
};

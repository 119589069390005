import React from "react";
import ReactCountryFlag from "react-country-flag";
import HeartFilledIcon from "assets/heart_filled.svg";
import HeartOutlinedIcon from "assets/heart_outlined.svg";
import { BrandCard } from "models/brandCard";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/routes";
import { useAuth } from "context/authContext";

interface Props {
  product: BrandCard;
  onAddToFavorite: (productId: string) => void;
  isSingleProduct?: boolean;
}

export const ProductCard = ({
  product,
  onAddToFavorite,
  isSingleProduct,
}: Props) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  const onSelect = (id: string) => {
    navigate(`${routes.products}/${id}`);
  };

  return (
    <div
      className={`flex rounded-large shadow-card cursor-pointer min-w-[43vw] sm:min-w-[190px] ${
        isSingleProduct ? "w-1/2" : "w-full"
      }`}
    >
      <div className="w-full">
        <div className="relative h-24">
          <img
            src={product.logoUrl}
            alt={product.name}
            className="w-full rounded-t-large max-h-24"
            onClick={() => onSelect(product.id)}
          />
          {isLoggedIn && (
            <div className="absolute bottom-14 left-3/4 w-15 m-2 pl-1 pr-1">
              <img
                alt={product.name}
                src={product.isInWishlist ? HeartFilledIcon : HeartOutlinedIcon}
                className="h-5 w-5 transform transition-transform hover:scale-110"
                onClick={() => onAddToFavorite(product.id)}
              />
            </div>
          )}
          {product.discount && (
            <div className="absolute bottom-0 min-w-[50px] rounded-large m-2 text-sm font-medium bg-rose-600 text-white p-[2px]">
              -{product.discount}%
            </div>
          )}
        </div>
        <div className="ml-4 text-left" onClick={() => onSelect(product.id)}>
          <div className="mt-1 text-sm font-medium text-primary">
            {product.name.length > 20
              ? product.name.substring(0, 20)
              : product.name}
          </div>
          <div className="-mt-1">
            <span>
              <ReactCountryFlag
                countryCode={product.countryCode}
                svg
                className="rounded-md"
              />
              <span className="text-xs font-medium text-primary ml-2">
                {product.countryCode} &bull; {product.currencyCode}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import "./shimmer.css";

interface IShimmerEffect {
  width?: string;
  height?: string;
  borderRadius?: string;
  miniLoaderSizeOne?: string;
  miniLoaderSizeTwo?: string;
  miniLoaderSizeThree?: string;
}

const ShimmerEffect = ({
  width,
  height,
  borderRadius,
  miniLoaderSizeOne,
  miniLoaderSizeTwo,
  miniLoaderSizeThree,
}: IShimmerEffect) => {
  return (
    <div
      className="shimmer-wrapper shimmer-animation"
      style={{ width: width, height: height, borderRadius: borderRadius }}
    >
      {miniLoaderSizeOne && (
        <div className={`${miniLoaderSizeOne} shimmer-element`}></div>
      )}
      {miniLoaderSizeTwo && (
        <div className={`${miniLoaderSizeTwo} shimmer-element`}></div>
      )}
      {miniLoaderSizeThree && (
        <div className={`${miniLoaderSizeThree} shimmer-element`}></div>
      )}
    </div>
  );
};

export default ShimmerEffect;

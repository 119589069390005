import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { InputHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  searchIcon?: boolean;
  type?: string;
  quantityTextField?: boolean;
}

export const TextField = ({
  name,
  value,
  disabled,
  placeholder,
  searchIcon,
  type,
  onChange,
  className,
  onBlur,
  quantityTextField,
  ...props
}: InputProps) => {
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
  };

  return (
    <div className="relative flex items-center">
      {searchIcon && (
        <MagnifyingGlassIcon className="ml-3 h-5 w-5 text-input-icon absolute" />
      )}
      <input
        type={type || "text"}
        className={twMerge(
          `bg-white text-input-label text-sm rounded-large shadow-md block w-full p-2.5 focus:border-white-50 focus:outline-none ${
            searchIcon && "pr-3 pl-10 py-2"
          } ${quantityTextField ? "!h-6" : "h-11"}`,
          className
        )}
        name={name}
        onChange={onChange}
        onBlur={handleFocus}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

import { Header } from "components/common/header";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/routes";

export const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const onNavigateBack = () => navigate(routes.profile);

  return (
    <div>
      <Header
        headerTitle="Privacy Policy"
        hideMiniCart
        onNavigateBack={onNavigateBack}
      />
      <div className="mt-4 text-left scrollable-section">
        <div className="text-lg font-medium text-primary">Privacy Policy</div>
        <div className="mt-5 text-sm font-semibold text-primary">
          Your privacy is important to us.
        </div>
        <div className="mt-3 text-sm font-normal text-primary">
          In this privacy policy we explain how we collect, use, share and
          otherwise process your personal information. This policy applies to
          all personal information we process about you when you visit our
          website, (“Website”), purchase or use our services or otherwise
          interact with us. Our Website may contain links to other websites, we
          are not responsible for the privacy practices or the content of those
          other websites. We are only responsible for the collection and use of
          your personal data described in this privacy and cookies policy,
          unless we inform you otherwise. We may collect this information via
          cookies and similar technologies when you visit our Websites. This
          information does not identify our visitors directly and is used to
          measure number of visits, average time spent on our Website, pages
          viewed and website usage information. The personal data we may collect
          from you directly can include your name, email address, mobile
          telephone number, address, your purchasing activity, your payment
          information and information you give us when you contact our customer
          services team. In such cases, Company processes your personal details
          to provide the service – for example to recharge your mobile phone, to
          inform you about the status of a purchase and/or a payment, deal with
          a complaint, for fraud prevention or improvement of our services. From
          time-to-time, we may increase information you give us via our Website
          with information from other sources, such as information validating
          your address or other available information about businesses. This is
          to help us maintain the accuracy of the information we collect and to
          help us provide a better service. The voluntary sending of emails to
          the email addresses indicated in the Company’s website entails the
          acquisition of the sender’s email address, necessary to answer, and of
          any other data contained in the message. The same occurs in filling
          and sending the web forms present in the site.
        </div>
      </div>
    </div>
  );
};

import { Tab } from "@headlessui/react";
import { TabContent } from "./tabContent";
import React from "react";

interface Props {
  details: { [key: string]: string }; //This should contain an object with a key: value format for the content of the tab
}

export const DetailTab = ({ details }: Props) => {
  return (
    <div className="w-full max-w-sm px-2 py-2 mt-3 shadow-card rounded-large">
      <Tab.Group>
        <Tab.List className="flex space-x-2 justify-between">
          {Object.keys(details).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                `rounded-large py-1 px-2 text-sm font-medium leading-5 hover:bg-primary-blue hover:text-white whitespace-nowrap focus:outline-none",
                  ${selected && "bg-primary-blue text-white font-semibold"}`
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {Object.values(details).map((value: string, idx) => {
            return (
              <Tab.Panel key={idx} className="p-3 text-xs text-start">
                <TabContent content={value} />
              </Tab.Panel>
            );
          })}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

import { Option } from "models/options";
import React from "react";

interface Params {
  options: Option[];
  selected?: Option;
  setSelected: (value: Option) => void;
  close?: () => void;
}
export const PopoverSelection = ({
  options,
  selected,
  setSelected,
  close,
}: Params) => {
  return (
    <div className="relative flex flex-col gap-2 bg-white p-3">
      {options.map((item, index) => (
        <div
          key={index}
          className={`hover:bg-selected cursor-pointer rounded-large p-3 whitespace-nowrap ${
            selected?.value === item.value && "bg-selected"
          }`}
          onClick={() => {
            setSelected(item);
            close && close();
          }}
        >
          {" "}
          {item.name}{" "}
        </div>
      ))}
    </div>
  );
};

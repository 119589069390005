import { useEffect, useState } from "react";
import { BrandCard } from "models/brandCard";
import { Header } from "components/common/header";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/routes";
import { ProductCard } from "components/common/productCard";
import { BrandsListResponse } from "api/getBrandList";
import { getWishList } from "api/getWishlist";
import { useToast } from "components/common/toast/toastService";
import { removeFromWishlist } from "api/removeFromWishlist";
import { addToWishList } from "api/addToWishlist";
import ShimmerEffect from "components/common/loader/ShimmerEffect";
import WishlistEmpty from "assets/wishlistEmpty.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth } from "context/authContext";

export const WishList = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<BrandsListResponse | null>(null);
  const [initialLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const toast = useToast();
  const itemsPerPage = 20;
  const { isLoggedIn } = useAuth();

  const fetchData = async (page: number, isInitialLoad = false) => {
    try {
      if (isInitialLoad) {
        setInitialLoading(true);
      } else {
        setLoading(true);
      }
      const productsResponse = await getWishList({
        skipCount: page * itemsPerPage,
        maxResultCount: itemsPerPage,
      });
      setProducts((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            items: [...prevState.items, ...productsResponse.items],
          };
        } else {
          return productsResponse;
        }
      });
      setHasMore(productsResponse.items.length === itemsPerPage);
    } catch (error) {
      toast.open("Error", "Failed to fetch Wishlist", "error", 5000);
      setError(true);
    } finally {
      if (isInitialLoad) {
        setInitialLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchData(0, true);
    }
  }, []);

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    fetchData(page + 1);
  };

  const onAddToFavorite = (productId: string) => {
    const selectedProduct = products?.items.find(
      (product: BrandCard) => product.id === productId
    );

    if (selectedProduct?.isInWishlist) {
      onRemoveFromWishlist(productId);
    } else if (selectedProduct) {
      setProducts((prevState) => {
        if (!prevState) return null;
        const updatedItems = prevState.items.map((product: BrandCard) => {
          if (product.id === productId) {
            return { ...product, isInWishlist: !product.isInWishlist };
          }
          return product;
        });
        return { ...prevState, items: updatedItems };
      });

      addToWishList(productId);
    }
  };

  const onRemoveFromWishlist = async (productId: string) => {
    try {
      setProducts((prevState) => {
        if (!prevState) return null;
        const updatedItems = prevState.items.filter(
          (product: BrandCard) => product.id !== productId
        );
        return { ...prevState, items: updatedItems };
      });

      await removeFromWishlist(productId);
    } catch (error) {
      toast.open("Error", "Failed to remove from Wishlist", "error", 5000);
      console.error("Error removing from wishlist", error);
    }
  };

  const onNavigateBack = () => navigate(routes.profile);

  return (
    <div className="w-full scrollable-section">
      <Header
        headerTitle="Wishlist"
        hideMiniCart
        onNavigateBack={onNavigateBack}
      />

      {initialLoading && (
        <div className="grid grid-cols-2 gap-4 w-full">
          {Array.from({ length: 8 }).map((_, idx) => (
            <ShimmerEffect
              key={idx}
              width="100%"
              height="120px"
              borderRadius="20px"
              miniLoaderSizeOne="picture"
              miniLoaderSizeTwo="medium"
              miniLoaderSizeThree="small"
            />
          ))}
        </div>
      )}

      {!initialLoading && !isLoggedIn && (
        <div className="flex flex-col gap-4">
          <div className="text-7xl">Oops!</div>
          <div>
            You need to be logged in to <br /> view your wishlist
          </div>
          <button
            className="rounded-large cursor-pointer shadow-primary hover:shadow-clicked bg-gradient-to-r from-btn-from to-btn-to hover:from-btn-to hover:to-btn-to w-25 p-3 text-md font-medium text-white self-center"
            onClick={onNavigateBack}
          >
            Login
          </button>
        </div>
      )}

      {!initialLoading && error && (
        <div className="flex flex-col gap-4">
          <div className="text-7xl">Oops!</div>
          <div>
            We can't seem to find the <br /> page you're looking for
          </div>
          <button
            className="rounded-large cursor-pointer shadow-primary hover:shadow-clicked bg-gradient-to-r from-btn-from to-btn-to hover:from-btn-to hover:to-btn-to w-25 p-3 text-md font-medium text-white self-center"
            onClick={onNavigateBack}
          >
            Go back
          </button>
        </div>
      )}

      {!initialLoading && products?.items.length === 0 && (
        <div className="text-center">
          <div className="flex item-center justify-center">
            <img src={WishlistEmpty} alt="empty-wishlist" width={300} />
          </div>
          <h2 className="text-lg mb-2">Your Wishlist is empty</h2>
          <h3 className="text-sm mb-1">
            Seems like you don't have any wishes here
          </h3>
          <h3 className="text-sm mb-4">Make a wish</h3>
          <button
            className="rounded-large cursor-pointer shadow-primary hover:shadow-clicked bg-gradient-to-r from-btn-from to-btn-to hover:from-btn-to hover:to-btn-to w-30 p-3 text-md font-medium text-white"
            onClick={() => navigate(routes.index)}
          >
            Start Shopping
          </button>
        </div>
      )}

      {!initialLoading && products && products.items.length > 0 && (
        <InfiniteScroll
          dataLength={products.items.length}
          next={loadMore}
          hasMore={hasMore}
          loader={
            <div className="grid grid-cols-2 gap-4 pt-2">
              {Array.from({ length: 4 }).map((_, idx) => (
                <ShimmerEffect
                  key={idx}
                  width="100%"
                  height="120px"
                  borderRadius="20px"
                  miniLoaderSizeOne="picture"
                  miniLoaderSizeTwo="medium"
                  miniLoaderSizeThree="small"
                />
              ))}
            </div>
          }
        >
          <div className="mt-3 grid grid-cols-2 gap-4">
            {products.items.map((product: BrandCard) => (
              <ProductCard
                key={product.id}
                product={product}
                onAddToFavorite={() => onAddToFavorite(product.id)}
              />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

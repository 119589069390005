import OrderSuccessImage from "assets/order_success.svg";
import OrderPartiallyFailedImage from "assets/order_partially_failed.svg";
import OrderFailedImage from "assets/order_failed.svg";

import { ORDER_STATUS_OPTIONS } from "models/order";

interface Props {
  status: string;
}

export const OrderStatusIcon = ({ status }: Props) => {
  return (
    <>
      <div className="flex justify-center mt-3">
        <img
          alt="status"
          src={
            status === ORDER_STATUS_OPTIONS.Delivered ||
            status === ORDER_STATUS_OPTIONS.Created
              ? OrderSuccessImage
              : status === ORDER_STATUS_OPTIONS.PartiallyFailed
              ? OrderPartiallyFailedImage
              : OrderFailedImage
          }
          className="h-28 w-28"
        />
      </div>
      <div className="flex justify-center mt-2 text-lg font-medium text-status-text">
        Order{" "}
        {status === ORDER_STATUS_OPTIONS.Delivered
          ? "Completed"
          : status === ORDER_STATUS_OPTIONS.PartiallyFailed
          ? "Partially Failed"
          : status === ORDER_STATUS_OPTIONS.Created
          ? "Created"
          : "Failed"}
      </div>
      <div className="flex justify-center text-sm font-medium text-status-desc">
        {status === ORDER_STATUS_OPTIONS.Delivered
          ? "Thank you for your purchase"
          : status === ORDER_STATUS_OPTIONS.PartiallyFailed
          ? "Some codes failed to be delivered"
          : status === ORDER_STATUS_OPTIONS.Created
          ? "Your order has been created"
          : "Please try again"}
      </div>
    </>
  );
};

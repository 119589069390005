import React from "react";
import CopyIcon from "assets/copy_icon.svg";
import { useToast } from "components/common/toast/toastService";

interface ICopyButtonProps {
  textToCopy: string;
}

const CopyButton: React.FC<ICopyButtonProps> = ({ textToCopy }) => {
  const toast = useToast();
  const handleCopy = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.open("Success", "Order ID copied to clipboard", "success", 5000);
      })
      .catch(() => {
        toast.open(
          "Error",
          "Failed to copy order ID to clipboard",
          "error",
          2000
        );
      });
  };

  return (
    <div className="rounded-large bg-white p-1">
      <img
        alt="logo"
        src={CopyIcon}
        className="cursor-pointer"
        onClick={handleCopy}
      />
    </div>
  );
};

export default CopyButton;

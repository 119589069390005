import { Fragment, InputHTMLAttributes, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { Option } from "models/options";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  data: any[];
  selectedOptions: Option[];
  onChangeSelect: (option: Option[]) => void;
  getOptionLabel?: (option: Option) => void;
  hideBorderShadow?: boolean;
  hideExpandIcon?: boolean;
  hideSearchIcon?: boolean;
  hideOutline?: boolean;
}

export const AutoCompleteMultiple = ({
  data = [],
  selectedOptions = [],
  onChangeSelect,
  getOptionLabel,
  placeholder,
  hideBorderShadow,
  hideExpandIcon,
  hideSearchIcon,
  hideOutline,
}: InputProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const options =
    searchTerm === ""
      ? data
      : data.filter(
          (x) => x.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
        );

  const onSelect = (event: any) => {
    const selected = data.filter((d) =>
      event.some((item: any) => item === d.value)
    );
    onChangeSelect(selected);
  };

  return (
    <Combobox value={selectedOptions} onChange={onSelect} multiple>
      <div className="relative">
        <div className="w-full h-11">
          {!hideSearchIcon && (
            <MagnifyingGlassIcon className="ml-3 h-5 w-5 mt-3 text-input-icon absolute" />
          )}
          <Combobox.Input
            className={`bg-white text-primary text-sm rounded-3xl w-full h-10 p-2.5 focus:border-white-50 focus:outline-none ${
              !hideBorderShadow && "shadow-md block"
            } ${!hideSearchIcon && "pl-10"} ${
              !hideOutline && "border-2 border-selected"
            }`}
            displayValue={(option: any) => option?.name}
            onChange={(event) => setSearchTerm(event.target.value)}
            placeholder={placeholder || "Search"}
          />
          {!hideExpandIcon && (
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-6 w-6 mt-4 text-input-icon"
                aria-hidden="true"
              />
            </Combobox.Button>
          )}
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setSearchTerm("")}
        >
          <Combobox.Options className="absolute z-30 mt-3 w-80 overflow-auto rounded-2xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {Array.isArray(options) &&
            options.length === 0 &&
            searchTerm !== "" ? (
              <div className="relative select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              Array.isArray(options) &&
              options.map((option) => (
                <Combobox.Option
                  key={option?.value}
                  className={({ active }) =>
                    `select-none py-2 mx-2 my-1 pl-2 pr-4 rounded-2xl ${
                      active &&
                      selectedOptions.some((x: any) => x.value !== option.value)
                        ? "bg-option-border"
                        : "text-gray-900"
                    } ${
                      selectedOptions.some(
                        (x: any) => x.value === option.value
                      ) && "bg-option-border"
                    }`
                  }
                  value={option?.value}
                >
                  {({ active }) => (
                    <span
                      className={`block truncate ${
                        active ? "font-medium" : "font-normal"
                      }`}
                    >
                      {getOptionLabel ? getOptionLabel(option) : option?.name}
                    </span>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

import { addZero } from "./addZero";
import { MONTHS } from "./constants";


export const dateFormatter = (date: string) => {
  const day = new Date(date).getDate();
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth();
  return `${day} ${MONTHS[month]} ${year}`;
};

export const dateFormatterWithTime = (date: string) => {
  const day = new Date(date).getDate();
  const year = new Date(date).getFullYear();
  const hours = new Date(date).getHours();
  const month = new Date(date).getMonth();
  const minutes = new Date(date).getMinutes();

  return `${day} ${MONTHS[month]} ${year} ${addZero(hours)}:${addZero(minutes)}`;
};




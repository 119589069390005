import React from "react";
import { twMerge } from "tailwind-merge";

export const AVAILABLE_STATUSES = {
  success: "success",
  error: "error",
  warning: "warning",
  created: "created",
  processing: "processing",
};

type Props = {
  type: keyof typeof AVAILABLE_STATUSES;
  text?: string;
  className?: string;
};

const statusClasses = {
  [AVAILABLE_STATUSES.success]: "bg-success text-success-info",
  [AVAILABLE_STATUSES.error]: "bg-error text-error-info",
  [AVAILABLE_STATUSES.created]: "bg-[#cbd5e1] text-[#334155]",
  [AVAILABLE_STATUSES.processing]: "bg-[#93c5fd] text-[#0369a1]",
  [AVAILABLE_STATUSES.warning]: "bg-warning text-warning-info",
};

export const Badge = ({ type, text, className }: Props) => {
  return (
    <span
      className={twMerge(
        `px-2 py-0.5 rounded-large font-semibold ${
          statusClasses[type] || "bg-warning text-warning-info"
        }`,
        className
      )}
    >
      {text}
    </span>
  );
};

import React, { ReactNode, useState, useEffect } from "react";
import ToastContext from "./toastService";

interface ToastProviderProps {
  children: ReactNode;
}

type ToastType = "success" | "alert" | "error" | "info";

interface ToastStyles {
  [key: string]: { background: string; text: string };
}

export default function ToastProvider({ children }: ToastProviderProps) {
  const [toasts, setToasts] = useState<any[]>([]);

  const open = (
    title: string,
    message: string,
    type: ToastType,
    timeout = 20000
  ) => {
    const id = Date.now();
    setToasts((prevToasts) => [
      ...prevToasts,
      { id, title, message, type },
    ]);
    setTimeout(() => close(id), timeout);
  };

  const close = (id: any) => {
    setToasts((prevToasts) =>
      prevToasts.filter((toast) => toast.id !== id)
    );
  };

  const contextValue = {
    open,
    close,
  };

  const toastStyles: ToastStyles = {
    error: { background: "bg-[#ebc8c4]", text: "text-[#b3323a]" },
    info: { background: "bg-[#cce8f4]", text: "text-[#4a7b9a]" },
    alert: { background: "bg-[#f8f3d6]", text: "text-[#807f78]" },
    success: { background: "bg-[#def2d6]", text: "text-[#607458]" },
  };

  // Listen for custom toast events
  useEffect(() => {
    const handleToastEvent = (event: CustomEvent) => {
      const { title, message, type, timeout } = event.detail;
      open(title, message, type, timeout);
    };

    // Listen for the global "toast" event
    window.addEventListener("toast", handleToastEvent as EventListener);

    return () => {
      window.removeEventListener("toast", handleToastEvent as EventListener);
    };
  }, []);

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      {toasts.length > 0 && (
        <div className="w-full max-w-md fixed z-100 top-3 left-3 right-3 mx-auto py-2 px-6">
          {toasts.map(({ id, title, type, message }) => (
            <div
              key={id}
              className={`${toastStyles[type]?.background} relative rounded-lg mb-1 px-3 py-1`}
            >
              <div className="flex w-full justify-between">
                <div className={`${toastStyles[type]?.text} text-sm font-medium`}>
                  {title}
                </div>
                <button onClick={() => close(id)} className="-mt-1">
                  x
                </button>
              </div>
              <div className={`${toastStyles[type]?.text} py-2 text-xs font-normal`}>
                {message}
              </div>
            </div>
          ))}
        </div>
      )}
    </ToastContext.Provider>
  );
}

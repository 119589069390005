import { Code } from "models/orderCode";
import CopyIcon from "assets/copy_icon.svg";
import { dateFormatter } from "utils/dateUtils";

interface Props {
  code: Code;
  onCopyCode: (text: string) => void;
}

export const CopyCode = ({ code, onCopyCode }: Props) => {
  return (
    <div className="mt-4 pb-2">
      <div className="text-sm font-medium text-primary">Copy code</div>
      <div className="mt-1 border light-grey rounded-large py-2 px-3 align-middle -ml-2">
        <div className="grid grid-cols-12">
          <div className="col-span-11 text-sm font-normal text-primary mt-1">
            {code?.code}
          </div>
          <div className="rounded-large bg-selected p-1 w-7 h-7 -ml-1">
            <img
              alt="logo"
              src={CopyIcon}
              className="cursor-pointer"
              onClick={() => onCopyCode(code?.code)}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12">
        <div className="mt-3 text-xs font-semibold text-primary col-span-3">
          <span>Pin:</span>
          <span className="ml-1">{code?.pin}</span>
        </div>
        <div className="text-right mr-1 mt-2 col-span-9">
          {code?.expiryDate && (
            <span className="text-xs font-semibold text-primary">
              Expires on:
            </span>
          )}
          {code?.expiryDate && (
            <span className="ml-1 text-xs font-semibold text-error-text">
              {dateFormatter(code?.expiryDate)}
            </span>
          )}
          <div className="text-xs font-semibold text-primary mt-1">
            Serial #: {code?.serial}
          </div>
        </div>
      </div>
    </div>
  );
};

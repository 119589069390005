import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "routes/routes";
import { Home } from "components/pages/home/homePage";
import { Orders } from "components/pages/orders/ordersPage";
import { ProductDetail } from "components/pages/productDetail/productDetailPage";
import { Profile } from "components/pages/profile/profilePage";
import { OrderDetail } from "components/pages/orderDetail/orderDetailPage";
import { NavMenu } from "components/layout/navMenu";
import { Checkout } from "components/pages/checkout/checkoutPage";
import { WishList } from "components/pages/wishlist/wishlist";
import { Settings } from "components/pages/settings/settings";
import { Help } from "components/pages/help/help";
import { PrivacyPolicy } from "components/pages/privacyPolicy/privacyPolicy";
import { AppContext } from "context/appContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import BinanceAuthCallback from "components/auth/BinanceAuthCallback";
import { AuthProvider } from "context/authContext";
import { useCart } from "hooks/useCart";
import VerifyPayment from "components/payment/VerifyPayment";
import { OrderStatus } from "components/pages/checkout/components/OrderStatus";

const queryClient = new QueryClient();

function App() {
  return (
    <div className="bg-background">
      <div className="App bg-background max-w-md mx-auto p-2 px-4">
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <AppContextProvider>
              <BrowserRouter>
                <Routes>
                  <Route element={<NavMenu />}>
                    <Route path={routes.index} element={<Home />} />
                    <Route path={routes.orders} element={<Orders />} />
                    <Route
                      path={routes.productDetail}
                      element={<ProductDetail />}
                    />
                    <Route
                      path={routes.orderDetail}
                      element={<OrderDetail />}
                    />
                    <Route path={routes.checkout} element={<Checkout />} />
                    <Route path={routes.profile} element={<Profile />} />
                    <Route path={routes.wishlist} element={<WishList />} />
                    <Route path={routes.settings} element={<Settings />} />
                    <Route path={routes.help} element={<Help />} />
                    <Route
                      path={routes.privacyPolicy}
                      element={<PrivacyPolicy />}
                    />
                  </Route>
                  <Route
                    path={routes.binanceAuth}
                    element={<BinanceAuthCallback />}
                  />
                  <Route
                    path={routes.verifyPayment}
                    element={<VerifyPayment />}
                  />
                  <Route path={routes.orderStatus} element={<OrderStatus />} />
                </Routes>
              </BrowserRouter>
            </AppContextProvider>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </div>
    </div>
  );
}

export default App;

const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { cart, setCart, fetchCart } = useCart();

  return (
    <AppContext.Provider value={{ cart, setCart, fetchCart }}>
      {children}
    </AppContext.Provider>
  );
};

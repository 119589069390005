import { Cart } from "models/cartItem";
import apiService from "./apiServices";
import { API_URL } from "utils/constants";

export const getCartItems = async (): Promise<Cart | null> => {
  try {
    const response = await apiService.get<Cart>(`${API_URL}/app/basket`);
    if (response.status === 302) {
      console.warn("Redirected to login, user not authenticated");
      // Handle the redirect to login here if needed
      return null;
    }
    return response.data;
  } catch (error) {
    console.error("Failed to fetch cart items", error);
    return null;
  }
};

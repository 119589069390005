import React, { ButtonHTMLAttributes, ReactElement } from "react";
import { twMerge } from "tailwind-merge";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  inverse?: boolean;
  icon?: ReactElement;
}
const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  className,
  inverse,
  disabled,
  icon,
  ...props
}) => {
  return (
    <button
      className={twMerge(
        `font-bold py-2 px-4 rounded-large cursor-pointer h-11 w-full shadow-primary hover:shadow-clicked ${
          inverse
            ? "bg-white hover:bg-white-200"
            : disabled
            ? "bg-disabled"
            : "bg-gradient-to-r from-btn-from to-btn-to hover:from-btn-to hover:to-btn-to"
        } ${disabled && "cursor-not-allowed"}`,
        className
      )}
      onClick={onClick}
      {...props}
      disabled={disabled}
    >
      <div
        className={
          icon ? "flex px-2 justify-between text-center" : "text-center"
        }
      >
        <span
          className={`${
            inverse
              ? disabled
                ? "text-disabled cursor-not-allowed"
                : "bg-gradient-to-r from-btn-from to-btn-to bg-clip-text text-transparent"
              : "text-white"
          }`}
        >
          {text}
        </span>
        {icon}
      </div>
    </button>
  );
};

export default Button;

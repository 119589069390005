import React from "react";

interface Props {
  label: string;
  value: any;
}

export const InfoList = ({ label, value }: Props) => {
  return (
    <div className="flex flex-row w-full justify-between p-1 items-center">
      <span className="text-xs font-medium">{label}</span>
      <div className="text-xs font-normal text-right">{value}</div>
    </div>
  );
};

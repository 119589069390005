import { useState } from "react";
import { TextField } from "components/common/textField";
import TrashImage from "assets/trash.svg";
import { Modal } from "components/common/modal";

export const Defaults = () => {
  const savedNumbers = [
    { name: "Mine primary", number: "+971 56 478 4857" },
    { name: "Mine secondary", number: "+971 56 478 4857" },
    { name: "Vicky", number: "+971 56 478 4857" },
  ];

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [record, setRecord] = useState<any>(null);

  const onDelete = (record: any) => {
    setRecord(record);
    setIsOpenDelete(true);
  };

  return (
    <div>
      <div className="text-left">
        <div className="ml-2">
          <div className="text-sm font-medium text-primary mt-2">Phone number</div>
          <TextField value={""} placeholder="" onChange={(e) => {}} className="mt-1 border-2 border-selected text-sm font-normal text-primary shadow-none" />

          <div className="text-sm font-medium text-primary mt-3">Country</div>
          <TextField value={""} placeholder="" onChange={(e) => {}} className="mt-1 border-2 border-selected text-sm font-normal text-primary shadow-none" />
          <div className="text-sm font-medium text-primary mt-3">Language</div>
          <TextField value={""} placeholder="" onChange={(e) => {}} className="mt-1 border-2 border-selected text-sm font-normal text-primary shadow-none" />
          <div className="text-sm font-medium text-primary mt-3">Fiat currency</div>
          <TextField value={""} placeholder="" onChange={(e) => {}} className="mt-1 border-2 border-selected text-sm font-normal text-primary shadow-none" />
        </div>

        <div className="ml-2">
          <div className="text-sm font-medium text-primary mt-6">Saved numbers</div>
          {savedNumbers.map((item, index) => {
            return (
              <div className="grid grid-cols-12" key={`number-${index}`}>
                <div className="mt-3 text-xs font-normal text-primary col-span-6">{item.name}</div>
                <div className="mt-3 text-xs font-normal text-primary col-span-5">{item.number}</div>
                <div className="mt-3 text-xs font-semibold text-primary">
                  <img src={TrashImage} alt="delete" className="ml-2 w-4 h-4 cursor-pointer" onClick={() => onDelete(item)} />
                </div>
              </div>
            );
          })}
        </div>

        {record && (
          <Modal
            isOpen={isOpenDelete}
            setIsOpen={setIsOpenDelete}
            title="Delete number?"
            content={
              <div className="py-2 text-xs font-normal text-primary">
                Are you sure you wan to delete the number{" "}
                {
                  <span className="font-semibold">
                    {record?.name} {record?.number}
                  </span>
                }{" "}
                from saved?
              </div>
            }
            onProceed={() => setIsOpenDelete(false)}
            cancelText="Cancel"
            proceedText="Send"
          />
        )}
      </div>
    </div>
  );
};

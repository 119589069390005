import { binanceAuth } from "api/binanceAuth";
import Spinner from "components/common/loader/spinner/Spinner";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BINANCE } from "utils/constants";
import { useToast } from "components/common/toast/toastService";
import { useAuth } from "context/authContext";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/routes";

const BinanceAuthCallback = () => {
  const location = useLocation();
  const toast = useToast();
  const { setIsLoggedIn } = useAuth();
  const [error, setError] = useState<any>(null);
  const [code, setCode] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get("code");

    if (code) {
      setCode(code);
      const authData = {
        provider: BINANCE,
        code,
      };

      binanceAuth(authData)
        .then((res) => {
          if (res) {
            setIsLoggedIn(true);
             navigate(routes.profile); // TODO this need to be changed to navigate to the previous page or dynamicall from checkout or profile page
            // navigate(-1);
            return;
          }
        })
        .catch((err) => {
          console.error("Binance auth failed", err);
          setError({
            message: "Binance auth failed",
            error: err,
            authData,
          });
        });
    }
  }, [location, setIsLoggedIn, toast]);

  // if (error) {
  //   return (
  //     <div>
  //       <Spinner />
  //       <div className="error-message">
  //         <h3>Error: {error.message}</h3>
  //         <h4>Full error: {JSON.stringify(error)}</h4>
  //         <pre>{JSON.stringify(error.error, null, 2)}</pre>
  //         <pre>{JSON.stringify(error.authData, null, 2)}</pre>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div>
      <Spinner />
      {/* {code && <h1>{code}</h1>} */}
    </div>
  );
};

export default BinanceAuthCallback;

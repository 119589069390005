import React, {
  Fragment,
  InputHTMLAttributes,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Combobox, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { Option } from "models/options";
import debounce from "lodash.debounce";
import { getBrandList } from "api/getBrandList";
import { getSection } from "utils/getSection";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  data?: Option[];
  selectedOption: Option | null;
  onChangeSelect: (option: Option) => void;
  onChangeSearchedBrands?: (brands: Option[]) => void;
  getOptionLabel?: (option: Option) => React.ReactNode;
  hideBorderShadow?: boolean;
  hideExpandIcon?: boolean;
  hideSearchIcon?: boolean;
  hideOutline?: boolean;
  allowCustomOption?: boolean;
  selectedSection?: string;
  clearFilters: () => void;
}

export const AutoComplete = ({
  data,
  selectedOption,
  onChangeSelect,
  onChangeSearchedBrands,
  getOptionLabel,
  placeholder,
  hideBorderShadow,
  hideExpandIcon,
  hideSearchIcon,
  hideOutline,
  allowCustomOption,
  selectedSection,
  clearFilters,
}: InputProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [options, setOptions] = useState<Option[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const autoCompleteRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (
      autoCompleteRef.current &&
      !autoCompleteRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const fetchBrandsOnSearch = useCallback(
    async (query: string) => {
      setLoading(true);
      const result = await getBrandList(
        { brandName: query },
        getSection(selectedSection)
      );
      const brandOptions = result.items.map((brand: any) => ({
        value: brand.id,
        name: brand.name,
        logoUrl: brand.logoUrl,
        countryCode: brand.countryCode,
        currencyCode: brand.currencyCode,
        isInWishlist: brand.isInWishlist,
        discount: brand.discount,
      }));
      setOptions(brandOptions);
      setLoading(false);
    },
    [selectedSection]
  );

  const debouncedFetchBrands = useMemo(
    () => debounce((query: string) => setDebouncedSearchTerm(query), 600),
    []
  );

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchBrandsOnSearch(debouncedSearchTerm);
    } else {
      setOptions([]);
    }
  }, [debouncedSearchTerm, fetchBrandsOnSearch]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === "") {
      setOptions([]);
      setOpen(false);
      setDebouncedSearchTerm("");
      debouncedFetchBrands("");
    } else if (!data) {
      debouncedFetchBrands(value);
    }
    setOpen(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (!data) {
        fetchBrandsOnSearch(searchTerm);
      }
      onChangeSearchedBrands && onChangeSearchedBrands(options);
      setOpen(false);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setDebouncedSearchTerm("");
    setOptions([]);
    setOpen(false);
    onChangeSearchedBrands && onChangeSearchedBrands([]);
    clearFilters();
  };

  const handleOptionClick = (option: Option) => {
    setOpen(false);
    onChangeSelect(option);
    onChangeSearchedBrands && onChangeSearchedBrands([option]);
  };

  return (
    <Combobox
      value={selectedOption}
      onChange={onChangeSelect}
      nullable
      ref={autoCompleteRef}
    >
      <div className="relative">
        <div className="w-full h-11">
          {!hideSearchIcon && (
            <MagnifyingGlassIcon className="ml-3 h-5 w-5 mt-3 text-input-icon absolute" />
          )}
          <Combobox.Input
            className={`bg-white text-primary text-sm rounded-3xl w-full h-10 p-2.5 focus:border-white-50 focus:outline-none ${
              !hideBorderShadow && "shadow-md block"
            } ${!hideSearchIcon && "pl-10"} ${
              !hideOutline && "border-2 border-selected"
            }`}
            displayValue={(option: any) => option?.name}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder || "Search"}
            value={searchTerm}
          />
          {searchTerm && (
            <XMarkIcon
              color="#202029"
              width={20}
              className="absolute top-[10px] right-0"
              onClick={handleClearSearch}
            />
          )}
          {!hideExpandIcon && (
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-6 w-6 mt-4 text-input-icon"
                aria-hidden="true"
                onClick={() => setOpen(false)}
              />
            </Combobox.Button>
          )}
        </div>
        <Transition
          as={Fragment}
          show={open}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setSearchTerm("")}
        >
          <Combobox.Options className="absolute z-30 mt-3 w-80 overflow-auto rounded-2xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {loading ? (
              <div className="relative select-none py-2 px-4 text-gray-700">
                Loading...
              </div>
            ) : options.length === 0 && searchTerm !== "" ? (
              debouncedSearchTerm ? (
                <div className="relative select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                allowCustomOption && (
                  <Combobox.Option
                    key={searchTerm}
                    value={{ value: null, name: searchTerm }}
                    className={({ active }) =>
                      `select-none py-2 mx-2 my-1 pl-2 pr-4 rounded-2xl ${
                        active && selectedOption?.name !== searchTerm
                          ? "bg-option-border"
                          : "text-gray-900"
                      } ${
                        selectedOption?.name === searchTerm &&
                        "bg-option-border"
                      }`
                    }
                    onClick={() => setOpen(false)}
                  >
                    {getOptionLabel
                      ? getOptionLabel({ value: "", name: searchTerm })
                      : searchTerm}
                  </Combobox.Option>
                )
              )
            ) : (
              options.map((option) => (
                <Combobox.Option
                  key={option?.value}
                  className={({ active }) =>
                    `select-none py-2 mx-2 my-1 pl-2 pr-4 rounded-2xl ${
                      active && selectedOption?.value !== option.value
                        ? "bg-option-border"
                        : "text-gray-900"
                    } ${
                      selectedOption?.value === option?.value &&
                      "bg-option-border"
                    }`
                  }
                  value={{ value: option?.value, name: option?.name }}
                  onClick={() => handleOptionClick(option)}
                >
                  {({ active }) => (
                    <span
                      className={`block truncate ${
                        active ? "font-medium" : "font-normal"
                      }`}
                    >
                      {getOptionLabel ? getOptionLabel(option) : option?.name}
                    </span>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import React from "react";

interface Props {
  title: string;
  detail: string;
}
export const Faq = ({ title, detail }: Props) => {
  return (
    <div className="w-full max-w-sm p-2">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between rounded-large p-2 text-left text-sm shadow-card font-semibold bg-white">
              <span className="ml-1">{title}</span>
              <ChevronUpIcon className={`${!open ? "rotate-180 transform" : ""} h-5 w-5 text-input-label mr-1`} />
            </Disclosure.Button>
            <Disclosure.Panel className="px-3 py-2 text-sm text-justify rounded-large shadow-card mt-2 bg-white">{detail}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

import React from "react";
import Zendesk from "react-zendesk";

const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;

// Extend the Window interface to include zESettings
declare global {
  interface Window {
    zE: (...args: any[]) => void;
  }
}

const setting = {
  color: {
    theme: "#4e40f3",
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help",
    },
  },
  contactForm: {
    fields: [{ id: "description", prefill: { "*": "" } }],
  },
};

const zendeskLoadEvent = () => {
  window.zE("webWidget", "hide");
  window.zE("webWidget:on", "close", () => {
    window.zE("webWidget", "hide");
  });
};

const ZendeskLoader: React.FC = () => {
  return (
    <div className="main">
      <div className="footer-v2">
        {ZENDESK_KEY && (
          <Zendesk
            defer
            zendeskKey={ZENDESK_KEY}
            {...setting}
            onLoaded={zendeskLoadEvent}
          />
        )}
      </div>
    </div>
  );
};

export default ZendeskLoader;

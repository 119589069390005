import React, { useState } from "react";
import parse from "html-react-parser";

interface Props {
  content: string;
}

export const TabContent = ({ content }: Props) => {
  const [expand, setExpanded] = useState(false);

  const toggleContentView = () => setExpanded((expand) => !expand);

  return content.length > 100 ? (
    <span>
      {" "}
      {!expand ? parse(content.substring(0, 100)) : parse(content || "")}
      <span className="text-primary-blue cursor-pointer font-bold whitespace-nowrap" onClick={toggleContentView}>
        {expand ? " Read Less" : "... Read More"}
      </span>{" "}
    </span>
  ) : (
    <span>{parse(content || "")}</span>
  );
};

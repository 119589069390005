import { User } from "models/User";
import apiService from "./apiServices";
import { API_URL } from "utils/constants";

export const sendOrderDetailsByOrderId = async (
  id: string,
  email: string
): Promise<User> => {
  const response = await apiService.put<User>(
    `${API_URL}/app/order-lookup/${id}/client-email`,
    null,
    {
      params: {
        clientEmail: email,
      },
    }
  );

  return response.data;
};

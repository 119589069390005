import ProfileImageDefault from "assets/profile_default.svg";
import { User } from "models/User";

interface Props {
  user?: User;
  imageUrl?: string;
}

export const ImageWithName = ({ imageUrl, user }: Props) => {
  return (
    <>
      <div className="flex justify-center">
        <div className="flex h-24 w-24 flex-col items-center justify-center rounded-full bg-selected">
          <img
            alt="profile_pic"
            src={imageUrl && imageUrl !== "" ? imageUrl : ProfileImageDefault}
            className="h-16 w-16 p-1"
          />
        </div>
      </div>
      <div className="text-lg font-medium text-primary break-words">{user?.name || user?.email || 'User'}</div>
    </>
  );
};

import { Popover, Transition } from "@headlessui/react";
import { Bars3BottomRightIcon } from "@heroicons/react/24/solid";
import { PopoverSelection } from "components/common/popoverSelection";
import Button from "components/common/button/button";
import { Fragment } from "react";
import { Option } from "models/options";

interface ButtonProps {
  selected?: Option;
  setSelected: (value: Option) => void;
 options: Option[];
}

export const SortButton = ({ selected, setSelected, options }: ButtonProps) => {
  return (
    <Popover className="">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
                ${open ? "" : "text-opacity-90"}
                group items-start rounded-large px-3 py-2 text-base font-medium text-white focus:outline-none 
                focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <Button
              text="Sort"
              onClick={() => {}}
              icon={<Bars3BottomRightIcon className="text-white h-6 w-6" />}
              className="w-20 h-6 p-0 text-md font-medium"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute -right-2 z-30 min-w-[10%] max-w-xs transform px-4">
              {({ close }) => (
                <div className="overflow-hidden rounded-large shadow-lg ring-1 ring-black ring-opacity-5">
                  <PopoverSelection
                    options={options}
                    selected={selected}
                    setSelected={(option) => {
                      setSelected({
                        ...option,
                        field: option.field,
                        isDescending: option.isDescending ?? true,
                      });
                      close();
                    }}
                    close={close}
                  />
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { Badge } from "components/common/badge";
import { Order } from "models/order";
import { ORDER_STATUS_OPTIONS } from "models/order";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/routes";
import { MAIN_CRYPTO_CURRENCY } from "utils/constants";
import { dateFormatterWithTime } from "utils/dateUtils";
import { getStatusType } from "utils/getStatusType";

interface Props {
  order: Order;
  onExpand: (id: string) => void;
  // saveScrollPosition: () => void;
}

export const ProductLayout = ({ order, onExpand }: Props) => {
  const navigate = useNavigate();

  const onNavigate = () => {
    // saveScrollPosition();
    navigate(`${routes.orders}/${order.id}`);
  };

  return (
    <div className="rounded-large bg-white shadow-lg ring-1 ring-black ring-opacity-5 px-2 py-3 min-h-[10%]">
      <div className="grid grid-cols-12 gap-5">
        <div
          className="col-span-3 w-24 h-14 cursor-pointer"
          onClick={onNavigate}
        >
          <img src={order.brandLogoUrl} alt="logo" className="rounded-xl" />
        </div>
        <div className="col-span-9 ml-4">
          <div
            className="w-5/6 text-xs font-semibold text-left text-primary ml-0 cursor-pointer"
            onClick={onNavigate}
          >
            {order.brandName}
          </div>
          <div className="grid grid-cols-12 gap-x-4">
            <div className="col-span-6 text-small font-normal text-left text-input-label">
              {order.creationTime && (
                <div className="cursor-pointer" onClick={onNavigate}>
                  {dateFormatterWithTime(order.creationTime)}
                </div>
              )}

              {order.orderStatus.toString() ===
              ORDER_STATUS_OPTIONS.Delivered ? (
                <div
                  className="flex max-w-full mt-3 cursor-pointer"
                  onClick={onNavigate}
                >
                  <span className="text-xs font-semibold text-primary mr-1">
                    {order.totalItemPrice}{" "}
                    {order.currencyCode ?? MAIN_CRYPTO_CURRENCY}
                  </span>{" "}
                  |{" "}
                  <span className="text-xs font-semibold text-primary ml-1">
                    {order.totalItemQuantity}x
                  </span>
                </div>
              ) : (
                <div
                  className="grid grid-cols-12 text-primary-blue cursor-pointer mt-1"
                  onClick={() => onExpand(order.id)}
                >
                  <div className="col-span-6 text-xs font-semibold">{`See ${
                    order.expanded ? "less" : "more"
                  }`}</div>
                  {order.expanded ? (
                    <ChevronUpIcon className="w-4 h-5 -ml-2" />
                  ) : (
                    <ChevronDownIcon className="w-4 h-5" />
                  )}
                </div>
              )}
            </div>
            <div
              className="col-span-6 text-center mt-1 cursor-pointer"
              onClick={onNavigate}
            >
              <div>
                <div className="flex flex-row-reverse">
                  <div className="text-center mr-1">
                    <Badge
                      text={order.orderStatus.toString()}
                      type={getStatusType(order.orderStatus.toString())}
                      className="text-xs font-semibold"
                    />
                  </div>
                </div>
              </div>

              <div className="text-right text-xs font-normal text-input-label mr-2">
                {order.deliveryMethod === "Email" ? "Via Email" : "In App"}
              </div>
            </div>
          </div>
        </div>
      </div>
      {order.expanded && (
        <div className="mt-3">
          <div className="grid grid-cols-12 gap-4 p-1">
            <div className="col-span-5 text-left text-xs font-medium text-primary">
              Product
            </div>
            <div className="col-span-2 text-left text-xs font-medium text-primary">
              Quantity
            </div>
            <div className="col-span-4 text-left text-xs font-medium text-primary">
              Price
            </div>
          </div>
          {order.orderItems &&
            order.orderItems.map((item, index) => (
              <div className="grid grid-cols-12 gap-4 p-1" key={index}>
                <div className="col-span-5 text-left text-xs font-normal text-primary">
                  {item.productName}-{item.unitFaceValue}
                </div>
                <div className="col-span-2 text-left text-xs font-normal text-primary">
                  {item.quantity}
                </div>
                <div className="col-span-4 text-left text-xs font-normal text-primary">
                  {item.totalPrice ?? 0}{" "}
                  {order.currencyCode ?? MAIN_CRYPTO_CURRENCY}
                </div>
              </div>
            ))}
        </div>
      )}

      {order.orderStatus.toString() !== ORDER_STATUS_OPTIONS.Delivered && (
        <div
          className="text-left ml-1 mt-3 cursor-pointer"
          onClick={onNavigate}
        >
          <div className="grid grid-cols-12 gap-11">
            <div className="col-span-1 text-base font-semibold text-primary">
              Total
            </div>
            <div className="col-span-6 text-base font-semibold text-primary">
              {order.totalItemPrice}{" "}
              {order.currencyCode ?? MAIN_CRYPTO_CURRENCY}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

import { AddBasketItem } from "models/basketItem";
import apiService from "./apiServices";
import { API_URL } from "utils/constants";
import { IAuthData } from "models/authData";

export const binanceAuth = async (authData: IAuthData): Promise<any> => {
  const { provider, code } = authData;
  const params = new URLSearchParams({
    provider,
    code,
    // verifyCode,
  }).toString();
  return apiService
    .post(
      `${API_URL}/app/external-account/login?${params}`,
      {},
      { withCredentials: true }
    )
    .then((res: any) => {
      return res;
    });
};

import { User } from "models/User";
import apiService from "./apiServices";
import { API_URL } from "utils/constants";

export const getProfile = async (): Promise<User> => {
  const response = await apiService.get<User>(
    `${API_URL}/account/my-profile`
  );

  return response.data;
};

import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Cart, CartItem } from "models/cartItem";
import { AppContext } from "context/appContext";
import { Select } from "components/common/select";
import { Option } from "models/options";
import { TextField } from "components/common/textField";

interface ICartQuantitySelector {
  showCustomInput: boolean;
  setShowCustomInput: React.Dispatch<React.SetStateAction<boolean>>;
  selectedQuantity: string;
  setSelectedQuantity: (quantity: string) => void;
  item: CartItem;
  updateCartCount: (item: CartItem) => void;
  setInvalidCart: (value: boolean) => void;
}

const QuantitySelector: React.FC<ICartQuantitySelector> = ({
  showCustomInput,
  setShowCustomInput,
  selectedQuantity,
  setSelectedQuantity,
  item,
  updateCartCount,
  setInvalidCart,
}) => {
  const { cart, setCart } = useContext(AppContext);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [options, setOptions] = useState<Option[]>([]);

  const checkAndToggleInputMode = useCallback(
    (quantity: number) => {
      if (quantity >= 10) {
        setShowCustomInput(true);
      } else {
        setShowCustomInput(false);
      }
    },
    [setShowCustomInput]
  );

  useEffect(() => {
    setSelectedQuantity(item.quantity.toString());
    checkAndToggleInputMode(item.quantity);
  }, [item.quantity, setSelectedQuantity, checkAndToggleInputMode]);

  useEffect(() => {
    const generatedOptions: Option[] = Array.from({ length: 9 }, (_, i) => ({
      name: String(i + 1),
      value: String(i + 1),
    }));
    generatedOptions.push({ name: "10+", value: "10+" });
    setOptions(generatedOptions);
    const initialOption =
      generatedOptions.find(
        (option) => option.value === item.quantity.toString()
      ) || null;
    setSelectedOption(initialOption);
  }, [item.quantity]);

  const handleCustomInputChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;
    setSelectedQuantity(value);
    if (!isNaN(parseInt(value, 10))) {
      setShowSaveButton(true);
    }
  };

  const handleUpdateClick = () => {
    const quantity = parseInt(selectedQuantity, 10);
    updateQuantity(item, quantity);
    setShowSaveButton(false);
    setSelectedQuantity(quantity.toString());
  };

  const updateQuantity = (item: CartItem, numericQuantity: number) => {
    if (cart && cart?.basketItems?.length > 0) {
      const selectedItem = cart.basketItems.find(
        (x: CartItem) =>
          x.id === item.id && x.unitFaceValue === item.unitFaceValue
      );
      if (selectedItem) {
        if (!isNaN(numericQuantity) && numericQuantity > 0) {
          setCart((prevCart: Cart) => ({
            ...prevCart,
            basketItems: prevCart.basketItems.map((x: CartItem) =>
              x.id === item.id && x.unitFaceValue === item.unitFaceValue
                ? { ...selectedItem, quantity: numericQuantity }
                : x
            ),
          }));
          updateCartCount({ ...selectedItem, quantity: numericQuantity });
          setInvalidCart(false);
        } else {
          setInvalidCart(true);
        }
      }
    }
  };

  const handleChangeSelect = (option: Option) => {
    setSelectedOption(option);
    setSelectedQuantity(option.value);

    if (option.value === "10+") {
      setShowCustomInput(true);
      setShowSaveButton(true);
      setSelectedQuantity("10");
    } else {
      setShowCustomInput(false);
      setShowSaveButton(true);
      const numericQuantity = parseInt(option.value, 10);
      if (!isNaN(numericQuantity)) {
        updateQuantity(item, numericQuantity);
      }
    }
  };

  return (
    <>
      {showCustomInput ? (
        <div className="flex items-center">
          <TextField
            type="number"
            name="quantity"
            value={selectedQuantity}
            onChange={handleCustomInputChange}
            className="border border-input-border-selected h-8 w-20"
            min="10"
            quantityTextField
          />
          {showSaveButton && (
            <button
              onClick={handleUpdateClick}
              className="text-[9px] p-3 w-10.5 h-5 font-bold py-2 rounded-large cursor-pointer shadow-primary hover:shadow-clicked bg-gradient-to-r from-btn-from to-btn-to hover:from-btn-to hover:to-btn-to false text-white ml-2 flex items-center justify-center"
            >
              Save
            </button>
          )}
        </div>
      ) : (
        <Select
          options={options}
          selectedValue={selectedOption}
          onChangeSelect={handleChangeSelect}
          showRadioButtons={false}
          isCartItemSelect
          className="relative z-[100]"
        />
      )}
    </>
  );
};

export default QuantitySelector;

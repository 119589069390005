import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { NavButton } from "components/common/button/navButton";
import HomeIcon from "assets/home.svg";
import OrderIcon from "assets/order.svg";
import PersonIcon from "assets/user.svg";
import { useState, useEffect } from "react";
import { routes } from "routes/routes";
import ZendeskLoader from "components/pages/help/zendesk";

enum Menus {
  Home = "home",
  Orders = "orders",
  Profile = "profile",
}

export const NavMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(Menus.Home);

  useEffect(() => {
    if (location.pathname === routes.index) {
      setSelected(Menus.Home);
    } else if (location.pathname === routes.orders) {
      setSelected(Menus.Orders);
    } else if (location.pathname === routes.profile) {
      setSelected(Menus.Profile);
    }
  }, [location.pathname]);

  const openHome = () => {
    setSelected(Menus.Home);
    navigate(routes.index);
  };

  const openOrders = () => {
    setSelected(Menus.Orders);
    navigate(routes.orders);
  };

  const openProfile = () => {
    setSelected(Menus.Profile);
    navigate(routes.profile);
  };

  return (
    <>
      <Outlet />
      <div className="fixed bottom-3 left-3 right-3 flex justify-between items-center bg-white shadow-nav-menu z-20 p-2 px-4 rounded-large max-w-sm mx-auto">
        <NavButton
          text="Home"
          selected={selected === Menus.Home}
          icon={<img src={HomeIcon} alt="Home Icon" className="h-7" />}
          onClick={openHome}
        />
        <NavButton
          text="Orders"
          selected={selected === Menus.Orders}
          icon={
            <img
              src={OrderIcon}
              alt="Order
           Icon"
              className="h-7"
            />
          }
          onClick={openOrders}
        />
        <NavButton
          text="Profile"
          selected={selected === Menus.Profile}
          icon={<img src={PersonIcon} alt="Person Icon" className="h-7" />}
          onClick={openProfile}
        />

        <ZendeskLoader />
      </div>
    </>
  );
};

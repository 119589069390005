import { Order } from "models/order";
import apiService from "./apiServices";
import { API_URL } from "utils/constants";

interface OrderResponse {
  totalCount: number;
  items: Order[];
}

interface OrderArguments {
  filter?: string;
  isDescending?: boolean;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
}

export const getOrders = async (args: OrderArguments): Promise<OrderResponse> => {

  const response = await apiService.get<OrderResponse>(
    `${API_URL}/app/order-lookup`,
    {
      params: args,
    }
  );

  return response.data;
};
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getCategories } from "api/getCategories";
import { Option } from "models/options";
import ShimmerEffect from "components/common/loader/ShimmerEffect";
import { useToast } from "components/common/toast/toastService";

interface Props {
  selected: string;
  setSelected: (value: string) => void;
}

export const CategoriesTab = ({ selected, setSelected }: Props) => {
  const [transformedCategories, setTransformedCategories] =
    useState<Option[]>();
  const isFetchedRef = useRef(false);
  const toast = useToast();

  const handleCategoryChange = (value: string) => {
    setSelected(value);
    localStorage.setItem("selectedCategory", value);
  };

  const {
    data: categories,
    error: categoriesError,
    isLoading: categoriesLoading,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategories,
    enabled: !isFetchedRef.current,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  useEffect(() => {
    const savedCategory = localStorage.getItem("selectedCategory");
    if (savedCategory) {
      setSelected(savedCategory);
    }
  }, [setSelected]);

  useEffect(() => {
    if (categories) {
      setTransformedCategories(
        [{ name: "All", value: "" }].concat(
          categories.items.map((item) => ({
            name: item.name,
            value: item.id,
          }))
        )
      );
    }
  }, [categories]);

  useEffect(() => {
    if (transformedCategories && selected) {
      const selectedElement = document.querySelector(
        `.category-scroll-container .category-${selected}`
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({ inline: "center" });
      }
    }
  }, [transformedCategories, selected]);

  if (categoriesError) {
    toast.open("Error", "Failed to fetch categories", "error", 5000);
  }

  return (
    <div className="flex flex-row max-h-11 px-2 py-1 gap-2 overflow-x-scroll shadow-card">
      {categoriesLoading ? (
        Array.from({ length: 6 }).map((_, idx) => (
          <ShimmerEffect
            key={idx}
            width="6rem"
            height="20px"
            borderRadius="20px"
          />
        ))
      ) : (
        <div className="flex overflow-x-auto category-scroll-container">
          {transformedCategories &&
            transformedCategories.map((category, index) => (
              <div
                className={`py-2 px-4 mr-2 rounded-full cursor-pointer whitespace-nowrap flex items-center justify-center category-${
                  category.value
                } ${
                  selected === category.value
                    ? "rounded-large bg-primary-blue text-white font-semibold"
                    : "bg-gray-50 text-gray-800"
                }`}
                key={index}
                onClick={() => handleCategoryChange(category.value)}
              >
                {category.name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

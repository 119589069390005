import { useCallback, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import validator from "validator";
import { Header } from "components/common/header";
import { Cart, CartItem } from "models/cartItem";
import { TextField } from "components/common/textField";
import { ProductCard } from "components/pages/checkout/components/productCard";
import Button from "components/common/button/button";
import ArrowCircleIcon from "assets/arrow_circle.svg";
import { InfoList } from "components/common/infoList";
import { AppContext } from "context/appContext";
import { EmptyCart } from "components/layout/emptyCart";
import {
  DEFAULT_PRICE_FIXED_DECIMALS,
  MAIN_CRYPTO_CURRENCY,
  ORDER_DELIVERY_METHODS,
  PAYMENT_METHODS,
  USD_TO_USDT,
} from "utils/constants";
import { createOrder } from "api/createOrder";
import { deleteItem as deleteItemUtil } from "utils/cartUtils";
import { handleLogin } from "utils/handleLogin";
import { useToast } from "components/common/toast/toastService";
import { PriceChangeModal } from "components/common/PriceChangeModal";
import { useAuth } from "context/authContext";
import { useLocalStorage } from "hooks/useLocalStorage";
import ShimmerEffect from "components/common/loader/ShimmerEffect";
import { loadCartFromLocalStorage } from "hooks/useCart";

export const Checkout = () => {
  const { cart, setCart, fetchCart } = useContext(AppContext);
  const [orderType, setOrderType] = useState("");
  const [email, setEmail] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const toast = useToast();
  const idempotencyId = uuidv4();
  const [emailError, setEmailError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [showPriceChangeModal, setShowPriceChangeModal] = useState(false);
  const { isLoggedIn } = useAuth();
  const [localCart] = useLocalStorage("cart");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const hasPriceChanged = cart?.basketItems.some(
      (item: CartItem) => item.isPriceChanged
    );
    if (hasPriceChanged) {
      setShowPriceChangeModal(true);
    }
  }, [cart?.basketItems]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchCart();
    }
  }, [isLoggedIn, fetchCart]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchCart().finally(() => setLoading(false)); // Set loading to false after fetching
    } else {
      setLoading(false); // No fetch needed if not logged in
    }
  }, [isLoggedIn, fetchCart]);

  const ShimmerEffectLoader = (
    <div className="p-5 flex flex-col gap-4">
      {Array.from({ length: 5 }).map((_, idx) => (
        <ShimmerEffect
          key={idx}
          width="100%"
          height="120px"
          borderRadius="20px"
          miniLoaderSizeOne="large"
          miniLoaderSizeTwo="medium"
          miniLoaderSizeThree="small"
        />
      ))}
    </div>
  );

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (orderType === "email" && newEmail && !validator.isEmail(newEmail)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError(null);
    }
  };

  const calculateTotalQuantity = useCallback((): number => {
    return (
      cart?.basketItems.reduce(
        (acc: number, item: CartItem) => acc + item.quantity,
        0
      ) || 0
    );
  }, [cart?.basketItems]);

  const onChangeCount = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (cart && cart?.basketItems?.length > 0) {
      const selectedItem = cart.basketItems.find(
        (x: CartItem) => x.id === id
      );
      const value = +event.target.value;
      if (selectedItem && value > 0) {
        selectedItem.quantity = value;
        setCart({
          ...cart,
          basketItems: cart.basketItems.map((item: CartItem) =>
            item.id === id ? selectedItem : item
          ),
        });
      }
    }
  };

  const handleDeleteItem = (item: CartItem) => {
    if (isLoggedIn) {
      deleteItemUtil(item, setCart);
    } else {
      const localCart: Partial<Cart> = loadCartFromLocalStorage();
      const updatedItems = localCart.basketItems?.filter(
        (x) =>
          !(x.productId === item.productId && x.unitFaceValue === item.unitFaceValue)
      );
  
      const updatedCart: Partial<Cart> = {
        ...localCart,
        basketItems: updatedItems,
        totalItemQuantity: updatedItems?.reduce(
          (sum: number, x: CartItem) => sum + x.quantity,
          0
        ),
        totalPrice: updatedItems?.reduce(
          (sum: number, x: CartItem) => sum + x.unitPrice * x.quantity,
          0
        ),
      };
  
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      setCart(updatedCart as Cart);
    }
  };

  const calculateSubtotal = useCallback(() => {
    const subTotal = cart?.basketItems.reduce(
      (acc: number, record: CartItem) => {
        const price = +record.unitPrice * record.quantity;
        return acc + price;
      },
      0
    );
    setSubTotal(+subTotal.toFixed(DEFAULT_PRICE_FIXED_DECIMALS));
  }, [cart?.basketItems]);

  useEffect(() => {
    if (cart && cart?.basketItems?.length > 0)
      calculateSubtotal();
  }, [cart, calculateSubtotal]);

  const handleCheckout = async () => {
    const totalQuantity = calculateTotalQuantity();
    if (totalQuantity > 500) {
      toast.open("Error", "Total quantity cannot exceed 500.", "error", 5000);
      return;
    }

    if (!isLoggedIn && orderType === "inapp") {
      handleLogin();
      setCart({ basketItems: [] });
      return;
    }

    if (cart && cart?.basketItems?.length > 0) {
      setSubmitting(true);
      const orderItems = cart.basketItems.map((item: CartItem) => {
        return {
          unitFaceValue: +item.unitFaceValue,
          unitPrice: +item.unitPrice,
          quantity: +item.quantity,
          productId: item.productId,
        };
      });

      const deliveryMethod = (() => {
        if (orderType === "email") {
          return isLoggedIn
            ? ORDER_DELIVERY_METHODS.EMAIL_AND_IN_APP
            : ORDER_DELIVERY_METHODS.EMAIL;
        }
        if (orderType === "inapp") {
          return ORDER_DELIVERY_METHODS.IN_APP;
        }
        return ORDER_DELIVERY_METHODS.EMAIL;
      })();

      const payload = {
        idempotencyId,
        clientEmail: email,
        currencyCode: MAIN_CRYPTO_CURRENCY,
        paymentMethod: PAYMENT_METHODS.BINANCE_PAY,
        totalPrice: subTotal,
        deliveryMethod,
        orderItems,
      };

      try {
        const result = await createOrder(payload);

        if (result && result.data && result.data.payment.paymentMetadata) {
          const paymentMetadata = JSON.parse(
            result.data.payment.paymentMetadata
          );
          const { certSn, merchantId, timeStamp, nonceStr, prepayId, paySign } =
            paymentMetadata;
          const binancePayload = {
            certSn,
            merchantId,
            timeStamp,
            nonceStr,
            prepayId,
            paySign,
          };
          sessionStorage.setItem("paymentId", result.data.payment.id);
          window.callBinanceMiniProgram(
            JSON.stringify({ action: "payment", binancePayload })
          );
          // setCart({ basketItems: [] });
          // if (!isLoggedIn) {
          //   localStorage.removeItem("cart");
          // }
          // toast.open("Success", "Order placed successfully!", "success", 5000);
        }
      } catch (error) {
        console.error(error);
        toast.open("Error", "Failed to create order", "error", 5000);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleCheckoutButtonText =
    (isLoggedIn && orderType === "inapp") || orderType === "email"
      ? "Buy with Binance"
      : "Log In and Buy with Binance";

  const handleModalConfirm = () => {
    setShowPriceChangeModal(false);
    handleCheckout();
  };

  useEffect(() => {
    isLoggedIn && setOrderType("inapp");
  }, [isLoggedIn]);

  return (
    <div className="w-full flex flex-col gap-4 text-center pt-1 scrollable-section">
      <Header />
      {showPriceChangeModal && (
        <PriceChangeModal
          isOpen={showPriceChangeModal}
          onClose={() => setShowPriceChangeModal(false)}
          onConfirm={handleModalConfirm}
        />
      )}

      <div className="text-left ml-1 text-lg font-normal text-primary">
        Checkout
      </div>

      {loading ? (
        ShimmerEffectLoader
      ) : (
        <div>
          {cart?.basketItems?.length > 0 ? (
            <>
              <div
                className={`h-auto max-h-96 ${
                  cart?.basketItems?.length > 3 ? "overflow-y-auto" : ""
                }`}
              >
                {cart.basketItems.map((item: CartItem, index: number) => {
                  return (
                    <ProductCard
                      key={`item-${index}`}
                      cartItem={item}
                      onDeleteItem={handleDeleteItem}
                      isCheckoutPage
                    />
                  );
                })}
              </div>

              <div className="text-left">
                {/* <div
                className="mt-4 text-xs font-semibold text-primary-blue cursor-pointer"
                onClick={() => setShowPromoCode(!showPromoCode)}
              >
                Add a promocode
              </div> */}
                {/* {showPromoCode && (
                <div className="mt-2">
                  <TextField
                    value={promoCode}
                    onChange={(e) => {
                      setPromoCode(e.target.value);
                    }}
                    className="border border-input-border-selected text-sm font-normal text-primary"
                  />
                </div>
              )} */}
                {!isLoggedIn && (
                  <div className="">
                    <div className="text-sm font-semibold text-primary mt-4">
                      How would you like to get an order?
                    </div>
                    <div className="flex mt-2 gap-5">
                      <Button
                        text="By email"
                        onClick={() => setOrderType("email")}
                        className="w-full shadow-none"
                        inverse={orderType !== "email"}
                      />
                      <Button
                        text="In app"
                        onClick={() => setOrderType("inapp")}
                        className="w-full shadow-none"
                        inverse={orderType !== "inapp"}
                      />
                    </div>

                    {orderType === "" && (
                      <div className="mt-2">
                        <div className="flex px-3 py-1  rounded-large text-xs font-normal bg-purple-light text-primary-blue">
                          <img
                            src={ArrowCircleIcon}
                            alt="arrow"
                            className="h-4"
                          />
                          <div className="ml-2">
                            Please choose how would you like to get an order
                          </div>
                        </div>
                      </div>
                    )}

                    {orderType === "email" && (
                      <div className="ml-2">
                        <div className="text-sm font-medium text-primary mt-2">
                          Enter your email
                        </div>
                        <TextField
                          value={email}
                          onChange={handleEmailChange}
                          className="-ml-1 mt-1 border-2 border-selected text-sm font-normal text-primary shadow-none"
                        />
                        {emailError && (
                          <p className="text-red-500 text-xs mt-1">
                            {emailError}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                )}

                <div className="px-1 mt-5">
                  <InfoList
                    label="Subtotal"
                    value={`${+subTotal.toFixed(
                      DEFAULT_PRICE_FIXED_DECIMALS
                    )} ${MAIN_CRYPTO_CURRENCY}`}
                  />

                  {/* <InfoList label="Discount" value={`-${discount} USDT`} /> */}
                </div>
                <div className="flex flex-row w-full justify-between px-2 mt-2">
                  <span className="text-lg font-medium text-primary">
                    Total
                  </span>
                  <div className="text-lg font-medium text-primary text-right">{`${+subTotal.toFixed(
                    DEFAULT_PRICE_FIXED_DECIMALS
                  )} ${MAIN_CRYPTO_CURRENCY}`}</div>
                </div>
                <div className="px-2 text-right text-xs font-medium text-primary">
                  {Number(
                    (+subTotal / USD_TO_USDT).toFixed(
                      DEFAULT_PRICE_FIXED_DECIMALS
                    )
                  )}{" "}
                  USD
                </div>

                <div className="mt-5 px-2">
                  {/* <div className="flex">
                  <input
                    type="checkbox"
                    className="w-5 h-5 border-2 accent-btn-from"
                  />
                  <div className="ml-2 -mt-1 text-xs font-medium text-primary w-72">
                    I have read and agreed to{" "}
                    <span className="text-primary-blue cursor-pointer">
                      privacy policy{" "}
                    </span>
                    and{" "}
                    <span className="text-primary-blue cursor-pointer">
                      Terms and Conditions
                    </span>
                  </div>
                </div> */}

                  {/* <div className="flex mt-3">
                  <input type="checkbox" className="w-5 h-5 accent-btn-from" />
                  <div className="ml-2 text-xs font-medium text-primary w-72">
                    Make me Log In to proceed
                  </div>
                </div> */}

                  <Button
                    text={handleCheckoutButtonText}
                    onClick={handleCheckout}
                    disabled={
                      submitting ||
                      !orderType ||
                      (orderType === "email" && (!email || !!emailError))
                    }
                    className="w-full shadow-none"
                  />

                  {/* <span>{promoCode}</span> */}
                </div>
              </div>
            </>
          ) : (
            <EmptyCart className="p-10" />
          )}
        </div>
      )}
    </div>
  );
};

import { Option } from "models/options";
// export const API_DEV_URL = process.env.REACT_APP_API_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const API_KEY =
  "QjJDLUJJTkFOQ0U6ZjhhZjgwNTYtZTIzNC00ZTQzLTkxMDAtMjZlM2Y1NmExMjg3";
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const USD_TO_USDT = 1.02;
export const MAIN_CRYPTO_CURRENCY = "USDT";
export const BINANCE = "Binance";
export const ORDER_DELIVERY_METHODS = {
  EMAIL: "Email",
  IN_APP: "InApp",
  EMAIL_AND_IN_APP: "EmailAndInApp",
};
export const PAYMENT_METHODS = {
  BINANCE_PAY: "BinancePay",
  PAYPAL: "PayPal",
  STRIPE: "Stripe",
};

export const PAYMENT_STATUSES = {
  REQUESTED: "Requested",
  PENDING: "Pending",
  CANCELED: "Canceled",
  PAID: "Paid",
  FAILED: "Failed",
  REFUNDING: "Refunding",
  REFUNDED: "Refunded",
  EXPIRED: "Expired",
};
export const DEFAULT_ORDER_ID = "00000000-0000-0000-0000-000000000000";
export const TESTING_AUTH_DATA = {
  userNameOrEmailAddress: process.env.REACT_APP_TESTING_AUTH_DATA_EMAIL,
  password: process.env.REACT_APP_TESTING_AUTH_DATA_PASSWORD,
  rememberMe: true,
};
export const PATH_NAMES = {
  DEVELOP: "dev-tamana.bamboocardportal.com",
  LOCALHOST: "localhost",
  DEVELOP_NEW: "d1wjvq868hf9j4.cloudfront.net",
  PRODUCTION: "d3jdaisevxtqie.cloudfront.net",
  PRODUCTION2: "tamana.bamboocardportal.com"
};
export const DEFAULT_PRICE_FIXED_DECIMALS = 5;
export const ORDER_STATUSES = {
  CREATED: "Created",
  PROCESSING: "Processing",
  SUCCEEDED: "Succeeded",
  FAILED: "Failed",
  PARTIALFAILED: "PartialFailed",
  REFUNDED: "Refunded",
  PARTIALLYREFUNDED: "PartiallyRefunded"
};
export const BASE_SORT_OPTIONS: Option[] = [
  {
    name: "New to old",
    value: "creationTime desc",
    field: "creationTime",
    isDescending: true,
  },
  {
    name: "Old to new",
    value: "creationTime asc",
    field: "creationTime",
    isDescending: false,
  },
  {
    name: "Name: A to Z",
    value: "name asc",
    field: "name",
    isDescending: false,
  },
  {
    name: "Name: Z to A",
    value: "name desc",
    field: "name",
    isDescending: true,
  },
];

import CheckIcon from "assets/check_icon.svg";
import { useEffect, useState } from "react";
import { AutoCompleteMultiple } from "components/common/autoCompleteMultiple";
import { getBrandList } from "api/getBrandList";

export const Preferences = () => {
  const [categoris, setCategories] = useState([
    { id: 1, name: "Games" },
    { id: 2, name: "Shopping" },
    { id: 3, name: "Entertainment" },
    { id: 4, name: "Travel" },
    { id: 5, name: "Finance" },
    { id: 6, name: "Food & restaurants" },
  ]);

  const [brands, setBrands] = useState<any>([]);
  const [selectedBrands, setSelectedBrands] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      const brands = await getBrandList();
      setBrands(brands);
    };
    fetchData();
  }, []);

  const onSelect = (category: any) => {
    const selectedCategory: any = categoris.find((x) => x.id === category.id);
    selectedCategory.selected = !selectedCategory.selected;
    setCategories((prevState) => {
      return prevState.map((item) => (item.id === category.id ? selectedCategory : item));
    });
  };

  return (
    <div className="mt-2">
      <div className="text-left text-sm font-medium text-primary">Categories</div>

      <div className="text-left mt-2">
        <div className="flex flex-wrap">
          {categoris.map((category: any, index) => {
            return (
              <div
                key={`category-${index}`}
                className={`flex flex-row w-fit px-3 py-2 mr-2 mt-2 rounded-large text-sm font-normal cursor-pointer ${category.selected && "bg-selected"}`}
                onClick={() => onSelect(category)}
              >
                <div className="mr-2">{category.name}</div>
                {category.selected && <img src={CheckIcon} className="w-6 h-6" alt='check-icon'/>}
              </div>
            );
          })}
        </div>
      </div>

      <div className="text-left text-sm font-medium text-primary mt-7">Chosen brands</div>

      <div className="pr-6 mt-2">
        <AutoCompleteMultiple
          placeholder="Search brands"
          data={brands}
          selectedOptions={selectedBrands}
          onChangeSelect={setSelectedBrands}
          hideExpandIcon
          hideOutline
        />
      </div>

      <div className="text-left mt-2">
        <div className="flex flex-wrap">
          {selectedBrands.map((category: any, index: number) => {
            return (
              <div key={`brand-${index}`} className="flex flex-row w-fit px-3 py-2 mr-2 mt-2 rounded-large text-sm font-normal cursor-pointer bg-selected">
                <div className="mr-2">{category.name}</div>
                <img src={CheckIcon} className="w-6 h-6" alt='check-icon'/>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import Button from "components/common/button/button";

interface PriceChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const PriceChangeModal: React.FC<PriceChangeModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center mx-4">
        <h2 className="text-lg font-bold mb-4">Price Change Alert</h2>
        <p className="mb-4">
          The price of one or more items in your cart has changed. Please review
          the updated prices before proceeding to checkout.
        </p>
        <div className="flex justify-center gap-4">
          <Button text="Review Cart" onClick={onClose} />
          <Button text="Proceed" onClick={onConfirm} inverse />
        </div>
      </div>
    </div>
  );
};

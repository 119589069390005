import React from "react";
import { AutoComplete } from "components/common/autoComplete";
import { CountryDropdown } from "components/common/countryDropdown";
import { SearchDropdown } from "components/common/searchDropdown";
import { Country } from "models/country";
import { Option } from "models/options";

interface Props {
  countries: Country[];
  currencies: Option[];
  selectedBrand: Option | null;
  selectedCountry: Country;
  selectedCurrency: Option;
  setBrand: (brand: Option | null) => void;
  setSearchedBrands: (brands: Option[]) => void;
  setCountry: (country: Country) => void;
  setCurrency: (currency: Option) => void;
  getOptionLabel: (option: Option) => React.ReactNode;
  selectedSection: string;
  clearFilters: () => void;
}

export const MainSearchField = ({
  countries,
  currencies,
  selectedBrand,
  selectedCountry,
  selectedCurrency,
  setBrand,
  setSearchedBrands,
  setCountry,
  setCurrency,
  getOptionLabel,
  selectedSection,
  clearFilters,
}: Props) => {
  return (
    <div className="rounded-large bg-white shadow-md focus:border-white-50 focus:outline-none pl-2 pr-4 h-11">
      <div className="justify-between flex">
        <div>
          <AutoComplete
            placeholder="Search brand"
            selectedOption={selectedBrand}
            onChangeSelect={setBrand}
            onChangeSearchedBrands={setSearchedBrands}
            getOptionLabel={getOptionLabel}
            hideBorderShadow
            hideExpandIcon
            hideOutline
            allowCustomOption
            selectedSection={selectedSection}
            clearFilters={clearFilters}
          />
        </div>
        <div className="flex ml-2 mt-1">
          <CountryDropdown
            inputPlaceholder="Type to search"
            data={[{ name: "All", value: "", code: "GL" }].concat(countries)}
            selectedOption={selectedCountry}
            onSelect={setCountry}
          />
          <div className="ml-2">
            <SearchDropdown
              inputPlaceholder="Type here"
              data={[{ name: "All", value: "" }].concat(currencies)}
              selectedOption={selectedCurrency}
              onSelect={setCurrency}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

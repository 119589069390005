import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
};

export const EmptyCart = ({ className }: Props) => {
  return (
    <div className={twMerge(`align-middle`, className)}>
      <div className="text-lg font-medium text-primary">Looks like you don’t have anything in your cart yet.</div>
      <div className="text-sm font-normal text-primary mt-3">Go shopping to buy your first item</div>
    </div>
  );
};

import { useContext, useState } from "react";
import { Cart, CartItem } from "models/cartItem";
import TrashImage from "assets/trash.svg";
import QuantitySelector from "components/layout/quantitySelector";
import { updateBasket } from "api/updateBasket";
import { MAIN_CRYPTO_CURRENCY } from "utils/constants";
import { useAuth } from "context/authContext";
import { loadCartFromLocalStorage } from "hooks/useCart";
import { AppContext } from "context/appContext";

interface Props {
  cartItem: CartItem;
  onDeleteItem: (item: CartItem) => void;
  isCheckoutPage?: boolean;
}

export const ProductCard = ({
  cartItem,
  onDeleteItem,
  isCheckoutPage,
}: Props) => {
  const [showCustomInput, setShowCustomInput] = useState<boolean>(true);
  const [selectedQuantity, setSelectedQuantity] = useState("1");
  const [, setInvalidCart] = useState(false);
  const { isLoggedIn } = useAuth();
  const { cart, setCart } = useContext(AppContext);

  const { brandName, brandLogoUrl, unitFaceValue, unitPrice, currency } =
    cartItem;

  const updateCartCount = async (item: CartItem) => {
    if (isLoggedIn) {
      try {
        await updateBasket({ itemId: item.id, quantity: item.quantity });
      } catch (error) {
        console.error("Error updating item in cart:", error);
      }
    } else {
      const localCart: Partial<Cart> = loadCartFromLocalStorage();
      const updatedItems = localCart.basketItems?.map((x) =>
        x.productId === item.productId && x.unitFaceValue === item.unitFaceValue
          ? { ...x, quantity: item.quantity }
          : x
      );

      const updatedCart: Partial<Cart> = {
        ...localCart,
        basketItems: updatedItems,
        totalItemQuantity: updatedItems?.reduce(
          (sum, x) => sum + x.quantity,
          0
        ),
        totalPrice: updatedItems?.reduce(
          (sum, x) => sum + x.unitPrice * x.quantity,
          0
        ),
      };

      localStorage.setItem("cart", JSON.stringify(updatedCart));
      setCart(updatedCart as Cart);
    }
  };

  return (
    <div
      className={`bg-white text-left pr-4 p-2.5 rounded-large mb-2 ${
        isCheckoutPage ? "shadow-card" : ""
      }`}
    >
      <div className="flex justify-between">
        <div className="flex">
          <img
            src={brandLogoUrl}
            alt={brandName}
            className="rounded-xl h-14 w-24"
          />
          <div className="flex flex-col justify-around text-xs font-semibold text-primary ml-2">
            <div>{`${brandName}-${unitFaceValue}`}</div>
            <div>{`${MAIN_CRYPTO_CURRENCY} ${unitPrice}`}</div>
          </div>
        </div>

        <div className="flex flex-col justify-around items-end">
          <img
            src={TrashImage}
            alt="delete"
            className="ml-1 mt-1 cursor-pointer"
            onClick={() => onDeleteItem(cartItem)}
            width={16}
            height={16}
          />
          <QuantitySelector
            showCustomInput={showCustomInput}
            setShowCustomInput={setShowCustomInput}
            selectedQuantity={selectedQuantity}
            setSelectedQuantity={setSelectedQuantity}
            item={cartItem}
            updateCartCount={updateCartCount}
            setInvalidCart={setInvalidCart}
          />
        </div>
      </div>
    </div>
  );
};

import { TextField } from "components/common/textField";
import { useEffect, useState } from "react";
import Button from "components/common/button/button";
import { updateProfile } from "api/updateProfile";
import { useToast } from "components/common/toast/toastService";
import { getProfile } from "api/getProfile";
import { useAuth } from "context/authContext";

export const Account = () => {
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [userName, setUserName] = useState<string>("");

  const [emailError, setEmailError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const toast = useToast();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await getProfile();
        setEmail(profile.email ?? "");
        setName(profile.name ?? "");
        setSurname(profile.surname ?? "");
        setPhoneNumber(profile.phoneNumber ?? "");
        setUserName(profile.userName ?? "");
      } catch (error) {
        console.error("Failed to fetch profile", error);
        toast.open("Error", "Failed to fetch profile", "error", 5000);
      }
    };

    isLoggedIn && fetchProfile();
  }, [isLoggedIn]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(emailRegex.test(email) ? null : "Invalid email format");
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    setPhoneError(
      phoneRegex.test(phoneNumber) ? null : "Invalid phone number format"
    );
  };

  const handleUpdateProfile = async () => {
    try {
      const response = await updateProfile({
        email,
        name,
        surname,
        phoneNumber,
        userName,
      });

      if (response) {
        toast.open("Success", "Profile updated successfully", "success", 5000);
        setIsChanged(false);
      }
    } catch (error: any) {
      console.error("Failed to update profile", error);
      toast.open(
        "Error",
        `Failed to update profile. ${error.response.data.error.message}`,
        "error",
        5000
      );
    }
  };

  const handleChange = (
    setter: (value: string) => void,
    value: string,
    validator?: (value: string) => void
  ) => {
    setter(value);
    setIsChanged(true);
    if (validator) validator(value);
  };

  return (
    <div className="pb-28">
      <div className="text-left">
        <div className="text-sm font-medium text-primary mt-2">Email</div>
        <TextField
          value={email}
          placeholder="johndoe@domain.com"
          onChange={(e) =>
            handleChange(setEmail, e.target.value, validateEmail)
          }
          className="mt-1 border-2 border-selected text-sm font-normal text-primary shadow-none"
        />
        {emailError && (
          <span className="text-red-600 text-xs">{emailError}</span>
        )}

        <div className="text-sm font-medium text-primary mt-3">Name</div>
        <TextField
          value={name}
          placeholder="John"
          onChange={(e) => handleChange(setName, e.target.value)}
          className="mt-1 border-2 border-selected text-sm font-normal text-primary shadow-none"
        />

        <div className="text-sm font-medium text-primary mt-3">Surname</div>
        <TextField
          value={surname}
          placeholder="Doe"
          onChange={(e) => handleChange(setSurname, e.target.value)}
          className="mt-1 border-2 border-selected text-sm font-normal text-primary shadow-none"
        />

        <div className="text-sm font-medium text-primary mt-3">
          Phone number
        </div>
        <TextField
          value={phoneNumber}
          placeholder="+123456789"
          onChange={(e) =>
            handleChange(setPhoneNumber, e.target.value, validatePhoneNumber)
          }
          className="mt-1 border-2 border-selected text-sm font-normal text-primary shadow-none"
        />
        {phoneError && (
          <span className="text-red-600 text-xs">{phoneError}</span>
        )}

        <Button
          text="Update Profile"
          onClick={handleUpdateProfile}
          className="w-full shadow-none mt-6"
          disabled={!!emailError || !!phoneError || !isChanged}
        />
      </div>
    </div>
  );
};

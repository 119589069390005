import HeartFilledIcon from "assets/heart_filled.svg";
import HeartOutlinedIcon from "assets/heart_outlined.svg";
import ShareIcon from "assets/share.svg";
import { useAuth } from "context/authContext";

interface Props {
  name: string;
  logoUrl: string | null;
  isInWishlist?: boolean;
  onAddToFavorite?: () => void;
}

export const InfoWithLogoCard = ({
  name,
  logoUrl,
  isInWishlist,
  onAddToFavorite,
}: Props) => {
  const { isLoggedIn } = useAuth();

  return (
    <>
      {isLoggedIn && (
        <div className="float-right mr-0.5">
          <img
            alt="wishlist-icon"
            src={isInWishlist ? HeartFilledIcon : HeartOutlinedIcon}
            className="h-5 w-5 transform transition-transform hover:scale-110 cursor-pointer"
            onClick={onAddToFavorite}
          />
          <img
            alt="share-icon"
            src={ShareIcon}
            className="mt-3 h-5 w-5 transform transition-transform hover:scale-110 cursor-pointer"
          />
        </div>
      )}
      <div className="flex justify-center mt-4">
        <img
          alt="logo"
          src={logoUrl || ""}
          className="h-32 max-w-56 rounded-large"
        />
      </div>
      <div className="mt-2 text-lg font-medium text-primary">{name}</div>
    </>
  );
};

import { Option } from "models/options";
import { BASE_SORT_OPTIONS } from "./constants";

export const getSortOptions = (context: "orders" | "brands"): Option[] => {
  return BASE_SORT_OPTIONS.map((option) => {
    if (option.field === "name") {
      return {
        ...option,
        field: context === "orders" ? "brandName" : "name",
      };
    }
    return option;
  });
};

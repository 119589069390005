import StarOutlinedIcon from "assets/star_outlined.svg";
import StarFilledIcon from "assets/star_filled.svg";

type Props = {
  starsCount: number;
  rating: number;
  setRating: (rating: number) => void;
};

export const Ratings = ({ starsCount, rating, setRating }: Props) => {
  return (
    <>
      {[...Array.from(Array(starsCount).keys())].map((number, index) => {
        return (
          <img
            key={`star-${index}`}
            alt="logo"
            src={number + 1 <= rating ? StarFilledIcon : StarOutlinedIcon}
            className="mt-3 h-4 w-4 transform transition-transform hover:scale-110 cursor-pointer mx-1"
            onClick={() => setRating(number + 1)}
          />
        );
      })}
    </>
  );
};

import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";

interface Props {
  label: string;
  onNavigate: () => void;
  count?: number;
  className?: string;
  boldTitle?: boolean;
}

export const NavigationCard = ({ label, count, onNavigate, boldTitle, className }: Props) => {
  return (
    <div className={twMerge(`rounded-large w-full max-w-sm shadow-card h-12  cursor-pointer`, className)} onClick={() => onNavigate()}>
      <div className="grid grid-cols-12 p-3">
        <div className={`col-span-10 text-left ml-2 text-base text-primary ${boldTitle ? " font-medium" : " font-normal"}`}>{label}</div>
        <div className="col-span-1">
          {count && <div className="flex h-5 w-5 flex-col items-center justify-center rounded-full bg-btn-from p-3 text-white">{count > 9 ? `9+` : count}</div>}
        </div>
        <div className="col-span-1 ml-2">
          <ChevronRightIcon className="text-input-label h-6 w-6" />
        </div>
      </div>
    </div>
  );
};

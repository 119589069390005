import React from "react";
import "./productDetailPage.css";

interface IShimmerEffect {
  width: string;
  height: string;
  borderRadius?: string;
  additionalStyles?: React.CSSProperties;
  shape?: string;
}

const ShimmerEffect = ({
  width,
  height,
  borderRadius,
  additionalStyles,
  shape='rectangular'
}: IShimmerEffect) => {
  const baseStyles: React.CSSProperties = {
    width,
    height,
    borderRadius,
    ...additionalStyles,
  };

  const starStyles: React.CSSProperties = shape === "star" ? {
    clipPath: "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
    width: height,
    height: height,
    background: 'linear-gradient(45deg, #d0dffb, #b9cff8)'
  } : {};

  return <div className="product-detail-page-loader" style={{...baseStyles, ...starStyles}} />;
};

const ProductImageShimmer = () => (
  <ShimmerEffect
    width="300px"
    height="200px"
    borderRadius="10px"
    additionalStyles={{ display: "block", margin: "0 auto" }}
  />
);


const StarsShimmer = () => (
  <div className="flex flex-row gap-3">
    {[...Array(5)].map((_, index) => (
      <ShimmerEffect key={index} width="20px" height="20px" shape="star" />
    ))}
  </div>
)

const TextLineShimmer = ({
  width,
  height,
  additionalStyles,
}: IShimmerEffect) => (
  <ShimmerEffect
    width={width}
    height={height}
    additionalStyles={additionalStyles}
  />
);

const ButtonShimmer = ({ width, height, borderRadius }: IShimmerEffect) => (
  <ShimmerEffect width={width} height={height} borderRadius={borderRadius} />
);

const ProductDetailPageLoader = () => (
  <div className="flex flex-col items-center gap-4">
    <ProductImageShimmer />
    <TextLineShimmer width="20rem" height="20px" />
    <div className="flex flex-row gap-3">
      <StarsShimmer />
    </div>
    <TextLineShimmer width="90%" height="20px" />
    <TextLineShimmer width="60%" height="20px" />
    <TextLineShimmer width="90%" height="20px" />
    <ButtonShimmer width="20rem" height="40px" borderRadius="25px" />
    <TextLineShimmer width="90%" height="20px" />
    <div className="flex flex-row gap-3">
      <ButtonShimmer width="10rem" height="40px" borderRadius="25px" />
      <ButtonShimmer width="10rem" height="40px" borderRadius="25px" />
    </div>
  </div>
);

export default ProductDetailPageLoader;

import apiService from "./apiServices";
import { API_URL } from "utils/constants";

export const removeFromBasket = async (itemId: string): Promise<any> => {
  return apiService
    .delete(`${API_URL}/app/basket/item/${itemId}`)
    .then((res: any) => {
      return res;
    });
};

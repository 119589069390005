import { AVAILABLE_STATUSES } from "components/common/badge";
import { ORDER_STATUSES } from "./constants";

export const getStatusType = (status: string): any => {
  switch (status) {
    case ORDER_STATUSES.CREATED:
      return AVAILABLE_STATUSES.created;
    case ORDER_STATUSES.PROCESSING:
      return AVAILABLE_STATUSES.processing;
    case ORDER_STATUSES.SUCCEEDED:
      return AVAILABLE_STATUSES.success;
    case ORDER_STATUSES.FAILED:
      return AVAILABLE_STATUSES.error;
    case ORDER_STATUSES.PARTIALFAILED:
      return AVAILABLE_STATUSES.error;
    case ORDER_STATUSES.REFUNDED:
      return AVAILABLE_STATUSES.warning;
    case ORDER_STATUSES.PARTIALLYREFUNDED:
      return AVAILABLE_STATUSES.warning;
    default:
      return "";
  }
};
